import { Injectable, Inject } from '@angular/core';
import { Observable } from '../../../node_modules/rxjs';
import 'es6-promise';
import 'whatwg-fetch';
import { HttpClient } from '@angular/common/http';
import { SPDocument } from '../models/SPDocument';
import { SPDocumentOneDrive } from '../models/SPDocumentOneDrive';
import { Constants } from '../app.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpServiceService {

  public PageContextInfo: string;
  public currentUserID: any;  

  private _doclibraryname: string;
  get doclibraryName(): string {
    return this._doclibraryname ? this._doclibraryname : localStorage.getItem('doclibraryname');
  }

  set doclibraryName(data: string) {
    this._doclibraryname = data;
    localStorage.setItem('doclibraryname', data);
  }

  private _documentLibraryName: any;
  get documentLibraryName(): any {
    return this._documentLibraryName ? this._documentLibraryName : JSON.parse(localStorage.getItem('documentLibraryName'));
  }

  set documentLibraryName(data: any) {
    this._documentLibraryName = data;
    localStorage.setItem('documentLibraryName', JSON.stringify(data));
  }
  private _documentFolder: string;
  get documentFolder(): string {
    return this._documentFolder ? decodeURIComponent(this._documentFolder) : decodeURIComponent(localStorage.getItem('documentFolder'));
  }

  set documentFolder(data: string) {
    this._documentFolder = data;
    localStorage.setItem('documentFolder', data);
  }

  private _tenantID: string;
  get tenantID(): string {
    console.log("2");
    return this._tenantID ? this._tenantID : localStorage.getItem('tenantID');
  }

  set tenantID(data: string) {
    console.log("1");
    this._tenantID = data;
    localStorage.setItem('tenantID', data);
  }

  private _checkDocumentStatus: string;
  get checkDocumentStatus(): string {
    return this._checkDocumentStatus ? this._checkDocumentStatus : localStorage.getItem('checkDocumentStatus');
  }

  set checkDocumentStatus(data: string) {
    this._checkDocumentStatus = data;
    localStorage.setItem('checkDocumentStatus', data);
  }

  private _isTenantRegister: string;
  get isTenantRegister(): string {
    return this._isTenantRegister ? this._isTenantRegister : localStorage.getItem('isTenantRegister');
  }

  set isTenantRegister(data: string) {
    this._isTenantRegister = data;
    localStorage.setItem('isTenantRegister', data.toString());
  }

  private _adminuserdetails: any;
  get adminuserdetails(): any {
    return this._adminuserdetails ? this._adminuserdetails : JSON.parse(localStorage.getItem('adminuserdetails'));
  }

  set adminuserdetails(data: any) {
    this._adminuserdetails = data;
    localStorage.setItem('adminuserdetails', JSON.stringify(data));
  }

  private _currentDocument: SPDocument = new SPDocument();
  get currentDocument(): SPDocument {
    return this._currentDocument ? this._currentDocument : JSON.parse(localStorage.getItem('DocData'));
  }

  set currentDocument(data: SPDocument) {
    this._currentDocument = data;
    this._currentDocument.pdfsource = data.EncodedAbsUrl.split(':')[1];
    this._currentDocument.documentFileSize = parseFloat((data.FileSizeDisplay / 1048576).toString()).toFixed(2);
    localStorage.setItem('DocData', JSON.stringify(data));
  }


  private _userLoginName: string;
  get userLoginName(): string {
    return this._userLoginName ? this._userLoginName : localStorage.getItem('userLoginName');
  }

  set userLoginName(data: string) {
    this._userLoginName = data;
    localStorage.setItem('userLoginName', data);
  }

  private _userName: string;
  get userName(): string {
    return this._userName ? this._userName : localStorage.getItem('userName');
  }

  set userName(data: string) {
    this._userName = data;
    localStorage.setItem('userName', data);
  }

  private _onedrive: string;
  get onedrive(): string {
    return this._onedrive ? this._onedrive : localStorage.getItem('onedrive');
  }

  set onedrive(data: string) {
    this._onedrive = data;
    localStorage.setItem('onedrive', data.toString());
  }

  private _currentDocumentOnedrive: SPDocumentOneDrive = new SPDocumentOneDrive();
  get currentDocumentOnedrive(): SPDocumentOneDrive {
    return this._currentDocumentOnedrive ? this._currentDocumentOnedrive : JSON.parse(localStorage.getItem('DocDataOnedrive'));
  }

  set currentDocumentOnedrive(data: SPDocumentOneDrive) {
    this._currentDocumentOnedrive = data;
    //this._currentDocumentOnedrive.pdfsource = data.EncodedAbsUrl.split(':')[1];
    this._currentDocumentOnedrive.documentFileSize = parseFloat((data.size / 1048576).toString()).toFixed(2);
    localStorage.setItem('DocDataOnedrive', JSON.stringify(data));
  }

  private _sharepointDriveInfo: string;
  get sharepointDriveInfo(): string {
    return this._sharepointDriveInfo ? this._sharepointDriveInfo : localStorage.getItem('sharepointDriveInfo');
  }

  set sharepointDriveInfo(data: string) {
    this._sharepointDriveInfo = data;
    localStorage.setItem('sharepointDriveInfo', data.toString());
  }

  private _accesstoken: any;
  get accesstoken(): any {
    return this._accesstoken ? this._accesstoken : localStorage.getItem('accesstoken');
  }

  set accesstoken(data: any) {
    this._accesstoken = data;
    localStorage.setItem('accesstoken', data);
  }

  private _fileName: string;
  get fileName(): string {
    return this._fileName ? this._fileName : localStorage.getItem('fileName');
  }

  set fileName(data: string) {
    this._fileName = data;
    localStorage.setItem('fileName', data);
  }

  private _folderName: string;
  get folderName(): string {
    return this._folderName ? this._folderName : localStorage.getItem('folderName');
  }

  set folderName(data: string) {
    this._folderName = data;
    localStorage.setItem('folderName', data);
  }

  private _isShareandDownload: boolean;
  get isShareandDownload(): boolean {
    return this._isShareandDownload ? this._isShareandDownload : Boolean(localStorage.getItem('isShareandDownload') == 'true' ? 1 : 0);
  }

  set isShareandDownload(data: boolean) {
    this._isShareandDownload = data;
    localStorage.setItem('isShareandDownload', data.toString());
  }

  private _sharepointrootdetails: any;
  get sharepointrootdetails(): any {
    return this._sharepointrootdetails ? this._sharepointrootdetails : JSON.parse(localStorage.getItem('sharepointrootdetails'));
  }

  set sharepointrootdetails(data: any) {
    this._sharepointrootdetails = data;
    localStorage.setItem('sharepointrootdetails', JSON.stringify(data));
  }

  public _isSaveDownloadClick: boolean;
  get isSaveDownloadClick(): boolean {
    return this._isSaveDownloadClick ? this._isSaveDownloadClick : Boolean(localStorage.getItem('isSaveDownloadClick') == 'true' ? 1 : 0);
  }

  set isSaveDownloadClick(data: boolean) {
    this._isSaveDownloadClick = data;
    localStorage.setItem('isSaveDownloadClick', data.toString());
  }

  private _drivefolder: string;
  get drivefolder(): string {
    return this._drivefolder ? this._drivefolder : localStorage.getItem('drivefolder');
  }

  set drivefolder(data: string) {
    this._drivefolder = data;
    localStorage.setItem('drivefolder', data);
  }

  private _driveName: string;
  get driveName(): string {
    return this._driveName ? this._driveName : localStorage.getItem('driveName');
  }

  set driveName(data: string) {
    this._driveName = data;
    localStorage.setItem('driveName', data);
  }


  constructor(private http: HttpClient) {
  }

  public pdfEditorJData: any;
  public PurchaseData: any;

  public checkIsTenantisRegister(aadTenantId) {
    var Data = {
      'TenantID': aadTenantId,
      'Email': this.userLoginName
    };
    return this.http.post(environment.apiBaseUrl + 'checkTenant', Data);
  }


  public getTenantAdmins(aadTenantId) {
    var Data = {
      'TenantId': aadTenantId
    };
    return this.http.post(environment.apiBaseUrl + 'getAdmins', Data);
  }



  public getPDFEditorGraphicsAnnotations(type: string): Promise<any> {
    return this.http.get(environment.apiBaseUrl + 'GetPDFEditorGraphicsAnnotations/' + type).toPromise();
  }

  public getPDFEditorGraphicsArrows(type: string): Promise<any> {
    return this.http.get(environment.apiBaseUrl + 'GetPDFEditorGraphicsArrows/' + type).toPromise();
  }

  public getPDFEditorGraphicsCallouts(type: string): Promise<any> {
    return this.http.get(environment.apiBaseUrl + 'GetPDFEditorGraphicsCallouts/' + type).toPromise();
  }

  public getStamps(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'GetStamps/' + this.tenantID);
  }

  public insertStamps(Data) {
    return this.http.post(environment.apiBaseUrl + 'InsertStamps', Data);
  }

  public DeleteStapms(stampsData: any) {
    var Data = [];
    for (var i = 0; i < stampsData.length; i++) {
      Data.push({ "Id": stampsData[i].id });
    }
    return this.http.post(environment.apiBaseUrl + 'DeleteStamps', Data);
  }

  // public saveDocument(file: any): Promise<any> {
  //   var url = decodeURIComponent(this._currentDocument.EncodedAbsUrl.split('.com')[1]);
  //   var siteServerRelativeUrl = url;
  //   var lastIndex = siteServerRelativeUrl.lastIndexOf("/");
  //   siteServerRelativeUrl = siteServerRelativeUrl.substring(0, lastIndex);
  //   var data = url.split('/');
  //   var filename = data[data.length - 1];

  //   return sp.web.getFileByServerRelativePath(siteServerRelativeUrl + '/' + encodeURIComponent(filename)).setContent(file);
  // }

  public clearEditingData() {

    /*if (this.onedrive == 'true') {
      var Data = {
        'DocumentGUID': this.currentDocumentOnedrive.id,
        'TenantID': this.tenantID
      };
    }
    else {*/
      var Data = {
        'DocumentGUID': this._currentDocument.GUID,
        'TenantID': this.tenantID
      };
    //}
    return this.http.post(environment.apiBaseUrl + 'clearJSONData', Data);
  }

  public updateUsage(ReamainingUsage) {
    var Data = {
      'TenantID': this.tenantID,
      'ReamainingUsage': ReamainingUsage
    };

    return this.http.post(environment.apiBaseUrl + 'UpdateUsage', Data);
  }

  public createContactInHubspotAfterSave() {
    if (this.adminuserdetails.sharepointEmail == this.userLoginName) {
      var Data = {
        "properties": [
          {
            "property": "email",
            "value": this.userLoginName
          },
          {
            "property": "firstname",
            "value": this.userName.split(" ")[0]
          },
          {
            "property": "lastname",
            "value": this.userName.split(" ")[1]
          },
          {
            "property": "pdf_editor",
            "value": "Active PDF Admin;Active PDF Editor"
          }
        ]
      };
    }
    else {
      var Data = {
        "properties": [
          {
            "property": "email",
            "value": this.userLoginName
          },
          {
            "property": "firstname",
            "value": this.userName.split(" ")[0]
          },
          {
            "property": "lastname",
            "value": this.userName.split(" ")[1]
          },
          {
            "property": "pdf_editor",
            "value": "Active PDF Editor"
          }
        ]
      };
    }
    return this.http.post(environment.apiBaseUrl + 'createContactInHubspotAfterSave', Data);
  }

  public copyToClipBoard(Data, type) {
    var inp = document.createElement('input');
    document.body.appendChild(inp);
    if (type == 'url') {
      inp.value = Data;
    }
    else {
      inp.value = Data;
    }
    inp.select();
    document.execCommand('copy', false);
    inp.remove();
  }

}
