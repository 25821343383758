import { Component, OnInit, NgZone } from '@angular/core';
import { AlertsService } from '../services/alerts.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { SpServiceService } from '../services/sp-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  public Data: string = "Chrome";

  constructor(public authService: AuthService, private alertsService: AlertsService, private router: Router, private _ngZone: NgZone, private spService: SpServiceService) {    
    this.Data = "Chrome";
    this.Data = this.checkBrowser();
    this._ngZone.run(() => {
      console.log('Outside Done!');
    });
  }

  ngOnInit() {
    console.log(this.authService.authenticated);
    console.log(navigator.userAgent);
    this.Data = this.checkBrowser();

    if (this.authService.authenticated && (this.Data == 'IE' || this.Data == 'Edge')) {
      this.blockUI.start('Please Wait.');
      this.authService.getAccessTokenredirect().then((res) => {
        if (this.blockUI) {
          this.blockUI.stop();
        }
        this.router.navigate(['/onedrive']);
      })
    }
    else if (this.authService.authenticated) {
      this.blockUI.start('Please Wait.');
      this.authService.getAccessToken().then((res) => {
        if (this.blockUI) {
          this.blockUI.stop();
        }
        this.router.navigate(['/onedrive']);
      })
    }

    this._ngZone.run(() => {
      console.log('Outside Done!');
    });
  }

  async signIn(): Promise<void> {
    await this.authService.signIn().then((res) => {
      this.router.navigate(['/onedrive']);
      this._ngZone.run(() => {
        console.log('Outside Done!');
      });
    }).catch(err => {
      console.log(err);
    });
  }

  async signInRedirect() {
    //this.authService.signIn2();
    await this.authService.signInRedirect()
  }

  
  checkBrowser() {
    var x = "";
    if (navigator.userAgent.indexOf("Edge") > -1 && navigator.appVersion.indexOf('Edge') > -1) {
      x = 'Edge';
    }
    else if (navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1) {
      x = 'Opera';
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1) {
      x = 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") != -1) {
      x = 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      x = 'Firefox';
    }
    else if ((navigator.userAgent.indexOf("Trident") != -1)) //IF IE > 10
    {
      x = 'IE';
    }
    else {
      x = 'unknown';
    }

    return x;
  }

}
