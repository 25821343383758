import { Component } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { CommonService } from './services/common.service';

@Component({
    selector: 'block-temp',
    styles: [`
    :host {
      text-align: center;
      color: #FFF !important;
    }    
  `],
    template: `
    <div class="block-ui-template container">
      <p-progressBar [value]="value" [mode]="value?'determinate':'indeterminate'"></p-progressBar>      
      <br />
      <h4>{{message}}</h4>
    </div>
  `
})
export class BlockTemplateComponent {

    value: number = 0;
    message: any;
    constructor(private commonService: CommonService) { }

    ngOnInit() {
        this.commonService.progressValue.subscribe((res) => {
            this.value = res;
            console.log(this.value);
        });

    }
}