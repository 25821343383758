export class SPDocument {
    public Id: number;
    public Modified: string;
    public FileSizeDisplay: number;
    public Editor: any;
    public Created: string;
    public Author: any;
    public CheckoutUser: any;
    public CheckoutUserId: number;
    public GUID: string;
    public OData__UIVersionString: string;
    public EncodedAbsUrl: string;
    public FileLeafRef: string;
    public FileRef: string;
    public pdfsource: string;
    public documentFileSize: string;

    constructor(obj?: any) {
        this.Id = obj && obj.Id || 0;
        this.Modified = obj && obj.Modified || '';
        this.FileSizeDisplay = obj && obj.FileSizeDisplay || 0;
        this.Editor = obj && obj.Editor || null;
        this.Created = obj && obj.Created || '';
        this.Author = obj && obj.Author || null;
        this.CheckoutUser = obj && obj.CheckoutUser || null;
        this.CheckoutUserId = obj && obj.CheckoutUserId || 0;
        this.GUID = obj && obj.GUID || '';
        this.OData__UIVersionString = obj && obj.OData__UIVersionString || '';
        this.EncodedAbsUrl = obj && obj.EncodedAbsUrl || '';
        this.FileLeafRef = obj && obj.FileLeafRef || '';
        this.FileRef = obj && obj.FileRef || '';
        this.pdfsource = obj && obj.pdfsource || '';
        this.documentFileSize = obj && obj.documentFileSize || '';
    }
}