import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { SpServiceService } from '../services/sp-service.service';
import { environment } from 'src/environments/environment';
import { EventEmitter } from 'events';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  // Should the collapsed nav show?
  showNav: boolean;
  public Data: string = "";
  public siteUrl = environment.siteUrl;
  public sharepointWebUrl: string;
  public oneDriveWebUrl: string;
  public isAdminUser: boolean = false;
  public tenantName: string;
  
  constructor(public authService: AuthService, public spService: SpServiceService, public commonService: CommonService) {
    this.commonService.sharepointWebUrl.subscribe((res) => {
      this.sharepointWebUrl = localStorage.getItem("hostWebURL");
    });
    
    this.commonService.tenantId.subscribe((res) => {
      if (res) {
        console.log('in......................');
        this.tenantName = this.spService.userLoginName.split('@')[1].split('.')[0];
        console.log(this.tenantName);
        console.log(this.spService.tenantID);
        this.spService.getTenantAdmins(this.spService.tenantID).subscribe((resData: any) => {
          if (resData) {
            for (let i = 0; i < resData.length; i++) {
              if (this.spService.userLoginName == resData[i].UserLoginName) {
                this.isAdminUser = true;
                break;
              }
            }
          }
        }, errData => {
          console.log(errData);
          //this.showError(errData);
        });
      }
    });

    this.commonService.oneDriveWebUrl.subscribe((res) => {
      this.oneDriveWebUrl = res.split('Documents')[0];
    });
  }

  ngOnInit() {
    this.showNav = false;
    this.Data = this.checkBrowser();
    console.log(this.Data)
    this.spService.isShareandDownload = false;

  }



  // Used by the Bootstrap navbar-toggler button to hide/show
  // the nav in a collapsed state
  toggleNavBar(): void {
    this.showNav = !this.showNav;
  }

  async signIn(): Promise<void> {
    await this.authService.signIn();
  }

  signOut(): void {
    // Temporary
    this.authService.signOut();
  }

  async signInRedirect() {
    //this.authService.signIn2();
    await this.authService.signInRedirect()
  }

  public gotoAdmin() {
    window.open('https://sohodragon.nyc/pdfeditor/?admin=only', '_blank');
  }

  public makeMeAdmin() {
    window.open('https://sohodragon.nyc/pdfeditor/?changeadmin=true&tenant=' + this.spService.tenantID + '&tname=' + this.tenantName + '&username=' + this.spService.userLoginName, '_blank');
  }

  sendShareableLink() {    
    this.commonService.emitShareLink('send');
  }

  downloadPDF() {
    this.commonService.emitDownloadPDF('download');    
  }

  checkBrowser() {
    var x = "";
    if (navigator.userAgent.indexOf("Edge") > -1 && navigator.appVersion.indexOf('Edge') > -1) {
      x = 'Edge';
    }
    else if (navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1) {
      x = 'Opera';
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1) {
      x = 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") != -1) {
      x = 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      x = 'Firefox';
    }
    else if ((navigator.userAgent.indexOf("Trident") != -1)) //IF IE > 10
    {
      x = 'IE';
    }
    else {
      x = 'unknown';
    }

    return x;
  }
}