import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { AlertsService } from './alerts.service';
import { User } from '../models/user';
import "isomorphic-fetch";
import { Client } from '@microsoft/microsoft-graph-client';
import { SpServiceService } from './sp-service.service';
import { Router } from '@angular/router';
import { Constants } from '../app.constants';
import { Image } from '../models/Image';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authenticated: boolean;
  public user: User;

  constructor(
    private msalService: MsalService,
    private alertsService: AlertsService,
    private spService: SpServiceService,
    private router: Router,
    private commonService: CommonService) {
    this.authenticated = this.msalService.getUser() != null;
    this.getUser().then((user) => { this.user = user });
  }

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn(): Promise<void> {

    let result = await this.msalService.loginPopup(Constants.OAuthSettings.scopes)
      .catch((reason) => {
        this.alertsService.add('Login failed', JSON.stringify(reason, null, 2));
      });

    if (result) {
      this.authenticated = true;
      this.user = await this.getUser();
      this.alertsService.removeall();
    }

    return result;
  }

  async signInRedirect(): Promise<void> {
    this.msalService.loginRedirect(Constants.OAuthSettings.scopes)
  }

  //custom function created for auth guard service
  public safeLogin(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.msalService.acquireTokenSilent(Constants.OAuthSettings.scopes).then(() => {
        this.authenticated = true;
        this.getUser().then((user) => {
          this.user = user;
          resolve(true);
        });

      }).catch(() => {
        this.msalService.loginPopup(Constants.OAuthSettings.scopes).then(() => {
          this.authenticated = true;
          this.getUser().then((user) => {
            this.user = user;
            resolve(true);
          });
        }).catch(() => {
          this.authenticated = false;
          resolve(false);
        });
      })
    })
  }

  public safeLoginredirect(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.msalService.acquireTokenSilent(Constants.OAuthSettings.scopes).then(() => {
        this.authenticated = true;
        this.getUser().then((user) => {
          this.user = user;
          resolve(true);
        });

      }).catch(() => {
        this.msalService.loginRedirect(Constants.OAuthSettings.scopes)
        this.getAccessTokenredirect();
        // this.msalService.loginPopup(OAuthSettings.scopes).then(() => {
        //   this.authenticated = true;
        //   this.getUser().then((user) => {
        //     this.user = user;
        //     resolve(true);
        //   });
        // }).catch(() => {
        //   this.authenticated = false;
        //   resolve(false);
        // });
      })
    })
  }

  // Sign out
  signOut(): void {
    this.msalService.logout();
    this.user = null;
    this.authenticated = false;
    localStorage.clear();
    this.router.navigate(['/home']);
  }

  // Silently request an access token
  async getAccessToken(): Promise<string> {
    let result = await this.msalService.acquireTokenSilent(Constants.OAuthSettings.scopes)
      .catch((reason) => {
        this.alertsService.add('Get token failed', JSON.stringify(reason, null, 2));
        //this.signOut();
        this.user = null;
        this.authenticated = false;
        this.router.navigate(['/home']);
      });

    // Temporary to display token in an error box
    //if (result) this.alertsService.add('Token acquired', result);

    if (result) {
      this.alertsService.removeall();
      this.spService.accesstoken = result;
    }

    return result;
  }


  getAccessTokenredirect(): Promise<string> {
    return this.msalService.acquireTokenSilent(Constants.OAuthSettings.scopes).then((res) => {
      this.spService.accesstoken = res;
      this.authenticated = true;
      return res;
    }).catch((reason) => {
      this.msalService.acquireTokenRedirect(Constants.OAuthSettings.scopes);
    });
  }

  private async getUser(): Promise<User> {
    if (!this.authenticated) return null;

    let graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        let token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });

    let graphUserPhoto: string;
    // Get the user from Graph (GET /me)    //responseType('blob')
    await graphClient.api('/me/photo/$value').header("Content-Type", "image/jpg").get().then((result) => {
      result.url = URL.createObjectURL(result);
      graphUserPhoto = result.url;
      console.log(graphUserPhoto);
    }).catch(err => {
      console.log(err);
      graphUserPhoto = "";
    });


    let graphUser = await graphClient.api('/me').get();
    console.log(graphUser)
    let user = new User();
    user.displayName = graphUser.displayName;
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail || graphUser.userPrincipalName;
    user.avatar = graphUserPhoto == null || graphUserPhoto == '' ? environment.siteUrl + '/assets/img/user.jpg' : graphUserPhoto;

    console.log(user);
    
    this.commonService.emitUserLoginName(user.email);
    this.spService.tenantID = this.msalService.getUser().idToken["tid"];
    this.spService.userLoginName = user.email;
    this.spService.userName = user.displayName;
    return user;
  }
}