import { TextEditing } from '../models/TextEditing';
import { Rectangleout } from '../models/Rectangleout';
import { Image } from '../models/Image';
import { Signature } from '../models/Signature';
import { Arrow } from '../models/Arrow';
import { Stamp } from '../models/Stamp';

export class Editing{
    public textEditings: TextEditing[];
    public rectangleouts: Rectangleout[];
    public images: Image[];
    public signatures: Signature[];
    public arrows: Arrow[];
    public stamps: Stamp[];

    constructor(obj?) {
        this.textEditings = obj && obj.textEditings || [];
        this.rectangleouts = obj && obj.rectangleouts || [];
        this.images =  obj && obj.images || [];
        this.signatures = obj && obj.signatures || [];
        this.arrows = obj && obj.arrows || [];
        this.stamps = obj && obj.stamps || [];
    }
}