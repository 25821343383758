import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TextEditing } from '../models/TextEditing';
import { Constants } from '../app.constants';
import FroalaEditor from 'froala-editor';
declare var $;

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styles: [Constants.textEditorCSS]
})
export class TextEditorComponent implements OnInit {

  @Input() public data: string;
  @Input() public myStyles: any;
  @Input() public id: number;
  @Output() public onTextChange: EventEmitter<TextEditing> = new EventEmitter<TextEditing>();
  @Output() public onTextDelete: EventEmitter<Number> = new EventEmitter<Number>();

  public positionX: any = 0;
  public positionY: any = 0;

  public options: Object = {
    placeholderText: '',
    toolbarInline: true,
    charCounterCount: false,
    toolbarBottom: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'textColor', 'clear', 'save'],
    quickInsertTags: null,
    key: Constants.froalaEditorKey,
    events: {
      'froalaEditor.focus': (e, editor) => {
      },
      'commands.after': (cmd, param1, param2) => {
        // Do something here.  
        if (cmd == "save") {
          this.saveData(this.data);
        }

        if (cmd == "clear") {
          this.deleteData(this.data);
        }
      }
    }
  };

  public editorConfig = {
    editable: true,
    spellcheck: false,
    minHeight: '50px',
    placeholder: 'Type something. Test the Editor... ヽ(^。^)丿',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    toolbar: ['bold', 'italic', 'underline', 'fontName', 'fontSize', 'color', 'delete'],
  };
  html: any;

  constructor() { }

  ngOnInit() {
    
    FroalaEditor.DefineIcon('clear', {NAME: 'remove', SVG_KEY: 'remove'});
    FroalaEditor.RegisterCommand('clear', {
      title: 'Clear HTML',
      icon:'clear',
      focus: false,
      undo: false,
      //callback:this.deleteData,
      refreshAfterCallback: true,
    });

    FroalaEditor.DefineIcon('save', {NAME: "floppy-o",template: "font_awesome"});
    FroalaEditor.RegisterCommand('save', {
      title: 'Save HTML',
      focus: false,
      icon:'save',
      undo: true,
      //callback:this.saveData,
      refreshAfterCallback: true,
      
    });
  }

  public saveData(editor) {
    editor = editor.replace('class="selected"','');
    this.myStyles.top = parseInt(this.myStyles.top.split('p')[0]) + parseInt(this.positionY) + 'px';
    this.myStyles.left = parseInt(this.myStyles.left.split('p')[0]) + parseInt(this.positionX) + 'px';
    this.onTextChange.emit({ id: this.id, html: editor, myStyles: this.myStyles, posx: this.positionX, posy: this.positionY });
  }

  public deleteData(editor) {
    editor = '';
    this.myStyles.top = parseInt(this.myStyles.top.split('p')[0]) + parseInt(this.positionY) + 'px';
    this.myStyles.left = parseInt(this.myStyles.left.split('p')[0]) + parseInt(this.positionX) + 'px';
    this.onTextChange.emit({ id: this.id, html: editor, myStyles: this.myStyles, posx: this.positionX, posy: this.positionY });
  }

  public onTextDragEnd(event) {
    console.log(event);
    if (event.x != 0 && event.y != 0) {
      this.positionX = event.x;
      this.positionY = event.y;
    }
  }

}
