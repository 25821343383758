import { Component } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { AuthService } from './services/auth.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'graph-tutorial';

  constructor(private authService: AuthService) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = '../../node_modules/pdfjs-dist/build/pdf.worker.js';

    $(document).keydown(function (e) {
      if (e.ctrlKey && e.altKey && e.keyCode == 80) {
        console.log('Production');
        var currentUrlHost = window.location.origin;
        var currentUrl = window.location.href;
       
        var newUrl = currentUrlHost + '/#' + currentUrl.split('#')[1];
        console.log(newUrl);
        
        //window.open(newUrl);

        window.location.replace(newUrl);
      }
      else if (e.ctrlKey && e.altKey && e.keyCode == 68) {
        console.log('Dev');

        var currentUrlHost = window.location.origin;
        var currentUrl = window.location.href;

        var newUrl = currentUrlHost + '/Dev/#' + currentUrl.split('#')[1];
        console.log(newUrl);
        
        window.location.replace(newUrl);
        
      }
      else if (e.ctrlKey && e.altKey && e.keyCode == 81) {
        console.log('QA');

        var currentUrlHost = window.location.origin;
        var currentUrl = window.location.href;

        var newUrl = currentUrlHost + '/QA/#' + currentUrl.split('#')[1];
        console.log(newUrl);
        
        window.location.replace(newUrl);
        
      }      
    });
  }
}
