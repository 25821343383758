import { Editing } from "../models/Editing";

export class PdfEditings {
    //pageNumber: number;
    public editings: Editing;

    constructor(obj?) {
        if(obj)
            this.editings = new Editing(obj.editings);
        else
            this.editings = new Editing();
    }
}

