import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from './alerts.service';
import { Observable, BehaviorSubject } from '../../../node_modules/rxjs';
import { Constants } from '../app.constants';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public pdfSource: Observable<string>;
  public sharelink: Observable<string>;
  public downloadPDF: Observable<string>;
  public sharepointWebUrl: Observable<string>;
  public oneDriveWebUrl: Observable<string>;
  public userLoginName: Observable<string>;
  public tenantId: Observable<string>;
  public progressValue: Observable<number>;

  private _pdfSource: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _send: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _download: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sharepointWebUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _onedriveUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _userLoginName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _tenantId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _progressValue: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient, private alertsService: AlertsService) {
    this.pdfSource = this._pdfSource.asObservable();
    this.sharelink = this._send.asObservable();
    this.downloadPDF = this._download.asObservable();
    this.sharepointWebUrl = this._sharepointWebUrl.asObservable();
    this.oneDriveWebUrl = this._onedriveUrl.asObservable();
    this.userLoginName = this._userLoginName.asObservable();
    this.tenantId = this._tenantId.asObservable();
    this.progressValue = this._progressValue.asObservable();
  }

  checkUserIsRegistered(data) {
    var tenantName = { 'TenantName': data }
    return this.http.post(environment.apiBaseUrl + 'isTenantRegisterByName', tenantName).toPromise();
  }

  getSharepointFileGUID(data,tenantId) {
    var fileUrldata = { 'FileUrl': data ,'tenantId':tenantId}
    return this.http.post(environment.apiBaseUrl + 'getSharepointGUID', fileUrldata).toPromise();
  }
  
  getPDFMasterTableDetails(tenantId){
    var data ={'tenantId':tenantId}
    return this.http.post(environment.apiBaseUrl + 'getPDFMarkupFileDetails', data).toPromise();
  }

  emitShareLink(data: string) {
    this._send.next(data);
  }

  emitDownloadPDF(data: string) {
    this._download.next(data);
  }

  emitWebUrl(data: string) {
    this._sharepointWebUrl.next(data);
  }

  emitOneDriveWebUrl(data: string) {
    this._onedriveUrl.next(data);
  }

  emitUserLoginName(data: string) {
    this._userLoginName.next(data);
  }

  emitTenantId(data: string) {
    this._tenantId.next(data);
  }

  emitProgressValue(data: number){
    this._progressValue.next(data);
  }

  // emitPDFSource(source: string) {
  //   this._pdfSource.next(source);
  // }
}
