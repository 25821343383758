import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';

import { AuthService } from './auth.service';
import { Drives } from '../models/event';
import { AlertsService } from './alerts.service';
import { SpServiceService } from './sp-service.service';
import { async } from 'q';

import * as request from 'superagent';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  private graphClient: Client;
  public docData: any = [];

  constructor(
    private authService: AuthService,
    private alertsService: AlertsService,
    private spService: SpServiceService) {

    // Initialize the Graph client
    if (!this.spService.accesstoken) {
      this.graphClient = Client.init({
        authProvider: async (done) => {
          // Get the token from the auth service
          let token = await this.authService.getAccessToken()
            .catch((reason) => {
              done(reason, null);
            });

          if (token) {
            done(null, token);
          } else {
            done("Could not get an access token", null);
          }
        }
      });
    }
    else {
      this.graphClient = Client.init({
        authProvider: async (done) => {
          done(null, this.spService.accesstoken);
        }
      });
    }
  }

  // async getOneDriveData(): Promise<Drives[]> {
  //   try {
  //     let result = await this.graphClient.api('me/drive/root/children').get();
  //     this.docData = [];
  //     for (var i = 0; i < result.value.length; i++) {
  //       if (result.value[i].file) {
  //         if (result.value[i].file.mimeType.indexOf('pdf') > -1) {
  //           this.docData.push(result.value[i]);
  //         }
  //       }
  //       else if (result.value[i].folder) {
  //         this.docData.push(result.value[i])
  //       }
  //     }

  //     console.log("docData", this.docData);
  //     return this.docData;

  //   } catch (error) {
  //     console.log(error);
  //     this.alertsService.add('Could not get events', JSON.stringify(error, null, 2));
  //   }
  // }

  public getOneDriveData() {
    return this.graphClient.api('me/drive/root/children').get();
  }

  public getRecentEditedPDF() {
    return this.graphClient.api('/me/drive/recent?orderby=lastModifiedDateTime%20desc').get();
  }

  public getTenantName() {
    return this.graphClient.api('/sites/root').get();
  }

  public getDocumentDownloadUrl(itemId) {
    return this.graphClient.api('me/drive/items/' + itemId).get();// + '?select=@microsoft.graph.downloadUrl'
  }

  public getSharepointDocumentDownloadUrl(driveId, itemId) {
    return this.graphClient.api('/drives/' + driveId + '/items/' + itemId).get();// + '?select=@microsoft.graph.downloadUrl'
  }

  public getDocumentDownloadUrlForSharepoint(data) {
    var driveApi = data.driveInfo.toString().split('?')[0];
    console.log(driveApi);
    return this.graphClient.api('sites/' + data.hostName + ',' + data.siteCollectionId + ',' + data.siteId + '/' + driveApi + '?select=@microsoft.graph.downloadUrl').get();
  }

  public uploadFile(file: File) {
    // console.log(file.size);
    // console.log(file.name);
    // return null;
    if (this.spService.onedrive == 'true') {
      var driveId = this.spService.currentDocumentOnedrive.parentReference.driveId;
      var itemId = this.spService.currentDocumentOnedrive.id;
      // var Data = {
      //   "item": {
      //     "@microsoft.graph.conflictBehavior": "rename"
      //   }
      // }
      return this.graphClient.api('me/drives/' + driveId + '/items/' + itemId + '/content').put(file)
    }
    else {
      var sharepointDriveInfo = this.spService.sharepointDriveInfo;
      return this.graphClient.api('me/' + sharepointDriveInfo + '/content').put(file);
    }

  }

  public getUploadSessio() {
    if (this.spService.onedrive == 'true') {
      var Data = {
        "item": {
          "@microsoft.graph.conflictBehavior": "replace",
          "name": this.spService.currentDocumentOnedrive.name
        }
      };
      var driveId = this.spService.currentDocumentOnedrive.parentReference.driveId;
      var itemId = this.spService.currentDocumentOnedrive.id;
      return this.graphClient.api('me/drives/' + driveId + '/items/' + itemId + '/createUploadSession').post(Data);
    }
    else {
      var Data = {
        "item": {
          "@microsoft.graph.conflictBehavior": "replace",
          "name": this.spService.fileName
        }
      };
      var sharepointDriveInfo = this.spService.sharepointDriveInfo;
      return this.graphClient.api('me/' + sharepointDriveInfo + '/createUploadSession').post(Data);
    }
  }

  public sendEmail(sendData: any) {
    return this.graphClient.api('me/sendMail').post(sendData);
  }

  public getAllUsers(Data) {
    return this.graphClient.api('users').filter("startswith(displayName,'" + Data + "')").get();
  }

  public checkOutCheckIn(action, driveId, itemId) {
    var comment = {
      "comment": action
    }

    if (!driveId && !itemId) {
      if (action == 'checkout')
        return this.graphClient.api(this.spService.sharepointDriveInfo + '/checkout').version('beta').post(comment);
      else
        return this.graphClient.api(this.spService.sharepointDriveInfo + '/checkin').version('beta').post(comment);
    }
    else {
      if (action == 'checkout')
        return this.graphClient.api('/drives/' + driveId + '/items/' + itemId + '/checkout').version('beta').post(comment);
      else
        return this.graphClient.api('/drives/' + driveId + '/items/' + itemId + '/checkin').version('beta').post(comment);
    }


  }


  public CheckInandClose(action, driveId, itemId) {
    var comment = {
      "comment": action
    }
    if (!driveId && !itemId) {
      /*if (action == 'checkout')
        return this.graphClient.api(this.spService.sharepointDriveInfo + '/checkout').version('beta').post(comment);
      else*/
        return this.graphClient.api(this.spService.sharepointDriveInfo + '/checkin').version('beta').post(comment);
    }
  }

  async getFolderData(driveId, folderId): Promise<Drives[]> {
    try {
      let result = await this.graphClient.api('me/drives/' + driveId + '/items/' + folderId + '/children').get();
      this.docData = [];
      for (var i = 0; i < result.value.length; i++) {
        if (result.value[i].file) {
          if (result.value[i].file.mimeType.indexOf('pdf') > -1) {
            this.docData.push(result.value[i]);
          }
        }
        else if (result.value[i].folder) {
          this.docData.push(result.value[i])
        }
      }

      console.log("docData", this.docData);
      return this.docData;

    } catch (error) {
      console.log(error);
      this.alertsService.add('Could not get events', JSON.stringify(error, null, 2));
    }
  }

  async uploadChunks(file, uploadUrl) {
    const homeService = this;
    var reader = new FileReader();

    // Variables for byte stream position
    var position = 0;
    var chunkLength = 320 * 1024 * 120;
    console.log('File size is: ' + file.size);
    var continueRead = true;
    let res;
    while (continueRead) {
      var chunk;
      try {
        continueRead = true;
        //Try to read in the chunk
        try {
          let stopB = position + chunkLength;
          console.log('Sending Asynchronous request to read in chunk bytes from position ' + position + ' to end ' + stopB)
          chunk = await this.readFragmentAsync(file, position, stopB);
          console.log("UploadChunks: Chunk read in of " + chunk.byteLength + " bytes.");
          if (chunk.byteLength > 0) {
            continueRead = true;
          } else {
            break;
          }
          console.log('Chunk bytes received = ' + chunk.byteLength);
        } catch (e) {
          console.log('Bytes Received from readFragmentAsync:: ' + e);
          break;
        }
        // Try to upload the chunk.
        try {
          console.log('Request sent for uploadFragmentAsync');
          res = await homeService.uploadChunk(chunk, uploadUrl, position, file.size);
          // Check the response.
          if (res[0] != 202 && res[0] != 201 && res[0] != 200)
            throw ("Put operation did not return expected response");
          if (res[0] === 201 || res[0] === 200) {
            console.log("Reached last chunk of file.  Status code is: " + res[0]);
            continueRead = false;
          }
          else {
            console.log("Continuing - Status Code is: " + res[0]);
            position = Number(res[1].nextExpectedRanges[0].split('-')[0])
          }

          console.log('Response received from uploadChunk.');
          console.log('Position is now ' + position);

        } catch (e) {
          console.log('Error occured when calling uploadChunk::' + e);
        }
        //
      } catch (e) {
        continueRead = false;
      }
    }

    return res;
    //location.reload(true);
  }
  // Reads in the chunck and returns a promise.
  readFragmentAsync(file, startB, stopB) {
    var frag;
    const reader = new FileReader();
    console.log('startBytes :' + startB + ' stopBytes :' + stopB)
    var blob = file.slice(startB, stopB);
    reader.readAsArrayBuffer(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = (event) => {
        console.log("onloadend called  " + reader.result);
        if (reader.readyState == reader.DONE) {
          frag = reader.result;
          resolve(frag);
        }
      };
    })
  }

  // Upload each chunk using PUT
  uploadChunk(chunk, uploadURL, position, totalLength) {//: Observable<any> {        
    let max = position + chunk.byteLength - 1;
    let contentLength = position + chunk.byteLength;

    console.log(chunk.byteLength);

    return new Promise((resolve, reject) => {
      console.log('uploadURL:: ' + uploadURL);

      try {
        console.log('Just before making the PUT call to uploadUrl.');
        let crHeader = `bytes ${position}-${max}/${totalLength}`;
        console.log('Content-Range header being set is : ' + crHeader);
        request
          .put(uploadURL)
          .set({ 'Content-Range': crHeader })
          .send(chunk)
          .end((err, res) => {
            if (err) {
              console.error(err);
              reject(err);
              return;
            }

            console.log(res.status);
            console.log(res.body);
            resolve([res.status, res.body]);

          });
      } catch (e) {
        console.log('exception inside uploadFragmentAsync::  ' + e)
        reject(e);
      }
    });
  }

  public checkFolderIsExist() {
    return this.graphClient.api('me/drive/root/children').filter("startswith(name,'PDFMarkUp')").get();
  }

  public createFolder() {
    var folder = {
      "name": "PDFMarkUp",
      "folder": {}
    }
    return this.graphClient.api('me/drive/root/children').post(folder);
  }

  public createSubFolder(itemId) {
    var folder = {
      "name": "Signature",
      "folder": {}
    }
    return this.graphClient.api('me/drive/items/' + itemId + '/children').post(folder);
  }


  public getSignatureFile(itemId) {

    return new Promise((resolve, reject) => {
      this.graphClient.api('me/drive/items/' + itemId + '/children').filter("startswith(name,'Signature')").get().then((res) => {
        this.graphClient.api('me/drive/items/' + res.value[0].id + '/children').filter("startswith(name, '" + this.spService.userLoginName + ".jpg')").get().then(res2 => {
          console.log(res2, 'res2..........................');
          if (res2.value.length > 0) {
            resolve(res2);
          }
          else {
            resolve(res);
          }
        });
      }).catch((err) => {
        reject(err);
      });
    });


  }

}