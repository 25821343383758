import { Component, OnInit, ViewChild, Inject, ViewChildren, HostListener, Input, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter, QueryList, NgZone } from '@angular/core';
import { PdfService } from '../services/pdf.service';
import { DatePipe } from '@angular/common';
import { SpServiceService } from '../services/sp-service.service';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import { INgWidgetConfig, INgWidgetContainerConfig, INgWidgetEvent } from 'ngx-draggable-widget';
import { TextEditing } from '../models/TextEditing';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { Observable, forkJoin } from 'rxjs';
import { PdfEditings } from '../models/PdfEditor';
import { Router } from "@angular/router";
import { Message } from 'primeng/components/common/api';
import { Constants } from '../app.constants';
import { CommonService } from '../services/common.service';
import { GraphService } from '../services/graph.service';
import { NavBarComponent } from '../nav-bar/nav-bar.component';
import { promise } from 'protractor';
import { resolve } from 'url';

//below function is used to render multiple pdf pages asynchronously on canvas and to improve performance.
var asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};
declare var $: any;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styles: [Constants.PDFViewerCSS],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements OnInit {

  public globalRef: Constants = Constants;
  public scale: number = 1.5;
  public pdfDoc: PDFDocumentProxy;
  public pdfPages: PDFPageProxy[] = [];
  public undoEditings: any[] = [];
  public currentPageActive: number = 0;
  public editings: PdfEditings[] = [];
  public deletedEditings: any[] = [];
  public undoOperations: any[] = [];
  public count: number = Math.floor(Math.random() * 10000000);
  public pdfFileName: string;
  public totalpagecount: number = 0;
  public arrowStyles: any;
  public msgs: Message[] = [];
  public textmsgs: Message[] = [];
  public display: boolean = false;

  public currentEditId: number;
  public currentSEditMouseEvent: MouseEvent;
  public editedText: string;

  public deletedEle: Event;
  public deletedEleId: number;
  public deletedEleType: string;
  public isDeleted: boolean = false;
  public isLoading: boolean = false;
  //public isSaveDownloadClick: boolean = false;
  public ispdfsizebig: boolean = false;

  public containerConfig: INgWidgetContainerConfig = {
    draggable: true,
    resizable: true,
    margins: [0, 0],
    allow_overlap: true,
    cascade: 'Off',
    col_width: 1,
    row_height: 1,
    fix_to_grid: false,
    maintain_ratio: false,
    min_cols: 1,
    min_rows: 1,
    min_height: 5,
    min_width: 5
  };

  public widgetConfig: INgWidgetConfig = {
    draggable: true,
    resizable: true,
    sizex: 100,
    sizey: 75,
    row: 1,
    col: 1,
    minCols: 1,
    minRows: 1,
    fixed: true
  };

  public imageContainerConfig: INgWidgetContainerConfig = {
    draggable: true,
    resizable: true,
    margins: [0, 0],
    allow_overlap: true,
    cascade: 'Off',
    col_width: 1,
    row_height: 1,
    fix_to_grid: false,
    maintain_ratio: false,
    min_cols: 1,
    min_rows: 1,
    min_height: 5,
    min_width: 5
  };

  public imageWidgetConfig: INgWidgetConfig = {
    draggable: true,
    resizable: true,
    sizex: 100,
    sizey: 75,
    row: 1,
    col: 1,
    minCols: 1,
    minRows: 1,
    fixed: true,
  };

  public stampContainerConfig: INgWidgetContainerConfig = {
    draggable: true,
    resizable: true,
    margins: [0, 0],
    allow_overlap: true,
    cascade: 'Off',
    col_width: 1,
    row_height: 1,
    fix_to_grid: false,
    maintain_ratio: false,
    min_cols: 1,
    min_rows: 1,
    min_height: 5,
    min_width: 5
  };

  public stampWidgetConfig: INgWidgetConfig = {
    draggable: true,
    resizable: true,
    sizex: 320,
    sizey: 120,
    row: 1,
    col: 1,
    minCols: 320,
    minRows: 120,
    fixed: true,
  };

  public calloutoptions: Object = {
    placeholderText: 'Edit Your Content Here!',
    toolbarInline: false,
    charCounterCount: false,
    toolbarBottom: false,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', '|', 'color'],
    key: Constants.froalaEditorKey,
  };
  public cPage: number;
  public Apage: number;

  @Input() public pdfSrc: string = '';
  @Input() public pdfPageNumber: number = 1;
  @Input() public selectedElement: string;

  @Input() public uplodedImage: any;
  @Input() public selectedAnnotations: any;
  @Input() public selectedArrows: any;
  @Input() public selectedCallouts: any;
  @Input() public selectedStamp: any;
  @Input() public selectedSignature: any;

  @Output() public onClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onPageTotal: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(SignaturePad) public signaturePad: SignaturePad;
  @ViewChildren("sigpad") public signaturePads: QueryList<SignaturePad>;

  constructor(
    private spService: SpServiceService,
    private domSanitizer: DomSanitizer,
    private pdfService: PdfService,
    private router: Router,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private graphService: GraphService,
    private _ngZone: NgZone
  ) {
    this.spService.isSaveDownloadClick = false;
    console.log('in...........new pdf src');
    this.commonService.pdfSource.subscribe((result) => {
      console.log('new pdf src', result);
      if (result) {
        this.pdfSrc = result;
      }
    })
  }

  ngOnInit() {
    console.log("ngoninit");
    this.isLoading = true;
    //var hideInfo = true;
    $(".sliderInfo").hide();
    setTimeout(() => {
      var rotateCSS;
      $('.arrowDiv').draggable({
        handle: '.roatedme',
        opacity: 0.001,
        helper: 'clone',
        drag: (event) => {
          var // get center of div to rotate
            pw = event.target,
            pwBox = pw.getBoundingClientRect(),
            center_x = (pwBox.left + pwBox.right) / 2,
            center_y = (pwBox.top + pwBox.bottom) / 2,
            // get mouse position
            mouse_x = event.pageX,
            mouse_y = event.pageY,
            radians = Math.atan2(mouse_x - center_x, mouse_y - center_y),
            degree = Math.round((radians * (180 / Math.PI) * -1) + 100);

          rotateCSS = 'rotate(' + (degree + 170) + 'deg)';

          $(event.target.children[6]).css({
            '-moz-transform': rotateCSS,
            '-webkit-transform': rotateCSS,
            '-o-transform': rotateCSS,
            'transform': rotateCSS
          });
        }
      });
      $(document).ready(function(){
        $(".sliderInfo").hide();
        /*$(".slide-toggle").click(function(){
          if(hideInfo){
            $(".sliderInfo").slideToggle();
          setTimeout(function(){
            hideInfo = false;
            $(".page").css("width","95%");
            $(".page2").css("width","95%");
            $(".pdfCanvas").css("width","95%");
            $(".pdfCanvas2").css("width","95%");
            $(".overlay").css("width","95%");
            $(".page2Viewer").css("width","95%");},500);
          }else{
            hideInfo = true;
            $(".page").css("width","800px");
            $(".page2").css("width","800px");
            $(".pdfCanvas").css("width","800px");
            $(".pdfCanvas2").css("width","800px");
            $(".overlay").css("width","800px");
            $(".page2Viewer").css("width","800px");
            
          setTimeout(function(){
            $(".sliderInfo").slideToggle();},200);
          }
          
      });*/
        $(".slide-toggle").click(function(){
          
          $(".sliderInfo").animate({
            width: "toggle"
        });
      });
    });
    }, 5000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pdfPageNumber) {
      if (changes.pdfPageNumber.currentValue != changes.pdfPageNumber.previousValue) {
        if (changes.pdfPageNumber.currentValue) {
          this.currentPageActive = changes.pdfPageNumber.currentValue;
          console.log(this.pdfPages);
          this.renderOtherPages(this.pdfPages[this.currentPageActive], 0);
        }
        else {
          this.currentPageActive = 0;
          this.renderOtherPages(this.pdfPages[this.currentPageActive], 0);
        }
      }
    }

    if (changes.pdfSrc) {
      if (changes.pdfSrc.currentValue != changes.pdfSrc.previousValue) {
        if (changes.pdfSrc.currentValue) {

          forkJoin([this.loadPDFDoc(), this.getEditingsData()]).subscribe((results) => {
            // Document Result                         
            if (results[0]) {
              this.pdfDoc = results[0];
              if (this.pdfDoc.numPages > 250) {
                this.stopLoadingAnimation2();
                //this.ispdfsizebig = true;
              }
              else {
                this.totalpagecount = this.pdfDoc.numPages;
                this.onPageTotal.emit(this.pdfDoc.numPages);

                setTimeout(() => {
                  this.loadFirstPage().subscribe(result => {
                    this.pdfPages = result;
                    this.renderFirstPage(this.pdfPages[0], 0);
                  });
                }, 1000);

                setTimeout(() => {
                  this.loadAllPages().subscribe(result => {
                    this.pdfPages = result;
                  });
                }, 2000);
              }

              if (results[1]) {
                var RData = results[1].substring(1, results[1].length - 1);
                var JSONData = JSON.parse(RData);
                results[1] = JSONData;

                let temp = [];
                for (var i = 0; i < results[1].length; i++) {
                  temp.push(new PdfEditings(results[1][i]));
                }
                this.editings = temp;
                this.pdfService.PdfEditings = this.editings;

                this.signaturePads.changes.subscribe((res) => {
                  if (res._results.length > 0) {
                    for (var j = 0; j < this.editings.length; j++) {
                      res._results.forEach((el, ind) => {
                        if (el.options.currentPage == j) {
                          if (this.editings[j].editings.signatures[el.options.itemIndex]) {
                            el.fromDataURL(this.editings[j].editings.signatures[el.options.itemIndex].signature);
                          }
                        }
                      });
                    }
                  }
                }, err => {
                  this.showError(err);
                });
              }
              else {

                let temp = [];
                for (var i = 0; i < this.pdfDoc.numPages; i++) {
                  temp.push(new PdfEditings());
                }
                this.editings = temp;

                this.signaturePads.changes.subscribe((res) => {
                  if (res._results.length > 0) {
                    for (var j = 0; j < this.editings.length; j++) {
                      res._results.forEach((el, ind) => {
                        if (el.options.currentPage == j) {
                          if (this.editings[j].editings.signatures[el.options.itemIndex]) {
                            el.fromDataURL(this.editings[j].editings.signatures[el.options.itemIndex].signature);
                          }
                        }
                      });
                    }
                  }
                }, err => {
                  this.showError(err);
                });
              }
            }
          }, err => {
            this.showError(err);
          });
        }
      }
    }
  }

  public getEditingsData() {
    console.log(this.spService.currentDocument.GUID);
    //return this.pdfService.oGetAllEditings(this.spService.currentDocument.GUID);
    return this.pdfService.oGetAllEditings(this.spService.currentDocument.GUID)
      .map((res) => res[0] ? res[0].JSONdata : null);
  }

  public loadPDFDoc(): Observable<PDFDocumentProxy> {
    return this.pdfService.oGetPdf(this.pdfSrc);
  }

  public loadFirstPage(): Observable<PDFPageProxy[]> {
    let arr: any[] = [];
    arr.push(this.pdfService.oGetPage(1));
    return forkJoin(arr);
  }

  public loadAllPages(): Observable<PDFPageProxy[]> {
    let arr: any[] = [];
    for (var i = 0; i < this.pdfDoc.numPages; i++) {
      arr.push(this.pdfService.oGetPage(i + 1));
    }
    return forkJoin(arr);
  }

  public renderFirstPage(pdfPage, index) {
    var obj = {
      'scale': this.scale
    }
    let viewport = pdfPage.getViewport(obj);
    let allCanvas = document.getElementsByClassName('pdfCanvas');
    let currentCanvas = allCanvas.item(0) as HTMLCanvasElement;
    let context = currentCanvas.getContext('2d');
    currentCanvas.height = viewport.height;
    currentCanvas.width = viewport.width;
    let renderContext = {
      canvasContext: context,
      viewport: viewport
    };
    pdfPage.render(renderContext);
    this.stopLoadingAnimation();
  }

  public renderOtherPages(pdfPage, index) {
    if (pdfPage) {
      var obj = {
        'scale': this.scale
      }
      let viewport = pdfPage.getViewport(obj);
      let allCanvas = document.getElementsByClassName('pdfCanvas');
      let currentCanvas = allCanvas.item(0) as HTMLCanvasElement;
      let context = currentCanvas.getContext('2d');
      currentCanvas.height = viewport.height;
      currentCanvas.width = viewport.width;
      let renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      pdfPage.render(renderContext);
    }
  }

  public renderallPages(pdfPage: PDFDocumentProxy, index) {
    // let scale = this.scale;
    // let viewport = pdfPage.getViewport(scale);
    // let allCanvas = document.getElementsByClassName('pdfCanvas2');
    // for (var i = 0; i < allCanvas.length; i++) {
    //   if (i == index) {
    //     let currentCanvas = allCanvas.item(i) as HTMLCanvasElement;
    //     let context = currentCanvas.getContext('2d');
    //     currentCanvas.height = viewport.height;
    //     currentCanvas.width = viewport.width;
    //     let renderContext = {
    //       canvasContext: context,
    //       viewport: viewport
    //     };
    //     pdfPage.render(renderContext);
    //   }
    // }

    return new Promise((resolve, reject) => {
      var obj = {
        'scale': this.scale
      }
      let viewport = pdfPage.getViewport(obj);      
      let allCanvas = document.getElementsByClassName('pdfCanvas2');      
      let currentCanvas = allCanvas.item(index) as HTMLCanvasElement;      
      let context = currentCanvas.getContext('2d');      
      currentCanvas.height = viewport.height;
      currentCanvas.width = viewport.width;
      let renderContext = {
        canvasContext: context,
        viewport: viewport
      };


      pdfPage.render(renderContext)
        .then(() => resolve())
        .catch((err) => reject(err))
    });
  }

  public stopLoadingAnimation() {
    setTimeout(() => {
      var modal = document.getElementById('myModal');
      modal.style.display = "block";
      var span = document.getElementById("close");
      var span2 = document.getElementById("close2");
      span.onclick = () => {
        modal.style.display = "none";
      };
      span2.onclick = () => {
        modal.style.display = "none";
      };
      window.onclick = (event) => {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
      this.isLoading = false;
      this.spService.isShareandDownload = true;
      this._ngZone.run(() => {
        console.log('Outside Done!');
      });
    }, 100);
  }


  public stopLoadingAnimation2() {
    setTimeout(() => {
      var modal = document.getElementById('myModal2');
      modal.style.display = "block";
      var span = document.getElementById("close1");
      span.onclick = () => {
        this.largepdffile();
      };
      window.onclick = (event) => {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
      this.isLoading = false;
      this.spService.isShareandDownload = false;
      this._ngZone.run(() => {
        console.log('Outside Done!');
      });
    }, 100);
  }

  public canvasClick(e: MouseEvent) {

    let temp = (e.srcElement || e.target) as HTMLElement;
    if (temp.className == "pdfCanvas") {
      let menuLinks: HTMLCollection = document.getElementsByClassName('selected');
      for (var i = 0; i < menuLinks.length; i++) {
        menuLinks[i].classList.remove('selected');
      }
      this.isDeleted = false;

      if (this.selectedElement) {
        if (this.spService.onedrive == 'false') {
          if (this.spService.checkDocumentStatus == 'checkin') {
            this.graphService.checkOutCheckIn('checkout', null, null).then((res) => {
              this.spService.checkDocumentStatus = 'checkout';
            }).catch((e) => {
              this.router.navigate(['/onedrive']);
            });
          }
        }
      }

      switch (this.selectedElement) {
        case "Text":
          this.createTextEditor(e);
          break;
        case "Whiteout":
          this.createWhiteout(e, 'Whiteout');
          break;
        case "Redact":
          this.createWhiteout(e, 'Redact');
          break;
        case "Highlight":
          this.createWhiteout(e, 'Highlight');
          break;
        case "Image":
          this.onImageUpload(e, 'Image');
          break;
        case "Annotations":
          this.onImageUpload(e, 'Annotations');
          break;
        case "Signature":
          //this.createSignature(e);
          this.onImageUpload(e, 'Signature');
          break;
        case "Export":
          //this.exportPdf();
          break;
        case "Arrow":
          this.addArrow(e, 'Arrow');
          break;
        case "Callout":
          this.addArrow(e, 'Callout');
          break;
        case "Stamps":
          this.addStamps(e);
          break;
      }
    }
  }

  public createTextEditor(e: MouseEvent) {
    let obj = { id: this.count++, html: '', myStyles: { 'position': 'absolute', 'top': (e.layerY) + 'px', 'left': (e.layerX) + 'px', 'text-align': 'left', 'z-index': '1000' } };
    this.editings[this.currentPageActive].editings.textEditings.push(obj);

    this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'textEditings' });
    this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });
    this.onClick.emit(this.selectedElement);
    this.saveEditingsData();
  }

  public onTextEditorChange(e: TextEditing) {
    for (let i = 0; i < this.editings[this.currentPageActive].editings.textEditings.length; i++) {
      if (this.editings[this.currentPageActive].editings.textEditings[i]) {
        if (e.id == this.editings[this.currentPageActive].editings.textEditings[i].id) {
          this.editings[this.currentPageActive].editings.textEditings[i] = e;
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public onTextDelete(data) {
    this.selectedElement = '';
    for (let i = 0; i < this.editings[this.currentPageActive].editings.textEditings.length; i++) {
      if (this.editings[this.currentPageActive].editings.textEditings[i]) {
        if (data == this.editings[this.currentPageActive].editings.textEditings[i].id) {
          this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.textEditings[i], 'EditingID': this.editings[this.currentPageActive].editings.textEditings[i].id, 'EditingType': 'textEditings' });
          this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.textEditings[i].id, 'UndoOperationFor': 'Delete' });
          this.editings[this.currentPageActive].editings.textEditings.splice(i, 1);
          this.onClick.emit();
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public createWhiteout(e: MouseEvent, type) {
    let obj: any = {};
    obj.id = this.count++;
    obj.type = type;
    obj.widgetConfig = Object.assign({}, this.widgetConfig);
    obj.widgetConfig.col = e.layerX;
    obj.widgetConfig.row = (e.layerY);

    this.editings[this.currentPageActive].editings.rectangleouts.push(obj);

    this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'rectangleouts' });
    this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });

    this.onClick.emit(this.selectedElement);

    this.saveEditingsData();
  }

  public removeThis(e: MouseEvent) {
    let temp = (e.srcElement || e.target) as HTMLElement;

    for (let i = 0; i < this.editings[this.currentPageActive].editings.rectangleouts.length; i++) {
      if (this.editings[this.currentPageActive].editings.rectangleouts[i]) {
        if (parseInt(temp.parentElement.parentElement.id) == this.editings[this.currentPageActive].editings.rectangleouts[i].id) {

          this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.rectangleouts[i], 'EditingID': this.editings[this.currentPageActive].editings.rectangleouts[i].id, 'EditingType': 'rectangleouts' });
          this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.rectangleouts[i].id, 'UndoOperationFor': 'Delete' });

          this.editings[this.currentPageActive].editings.rectangleouts.splice(i, 1);
          this.onClick.emit();
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public onComplete(e: INgWidgetEvent, data: number) {
    for (let i = 0; i < this.editings[this.currentPageActive].editings.rectangleouts.length; i++) {
      if (this.editings[this.currentPageActive].editings.rectangleouts[i]) {
        if (data == this.editings[this.currentPageActive].editings.rectangleouts[i].id) {
          Object.assign(this.editings[this.currentPageActive].editings.rectangleouts[i].widgetConfig, e);
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public onImageUpload(e: MouseEvent, type: string) {

    if (type == 'Annotations') {
      let obj: any = {};
      obj.id = this.count++;
      obj.url = this.selectedAnnotations;
      obj.imageWidgetConfig = Object.assign({}, this.imageWidgetConfig);
      obj.imageWidgetConfig.col = e.layerX;
      obj.imageWidgetConfig.row = e.layerY;
      this.editings[this.currentPageActive].editings.images.push(obj);

      this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'images' });
      this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });

      this.onClick.emit(this.selectedElement);
      setTimeout(() => {
        this.selectedElement = "Annotations";
      }, 500);
      this.saveEditingsData();
    }
    else if (type == 'Signature') {

      // let obj: any = {};
      // obj.id = this.count++;
      // obj.url = this.selectedSignature;
      // obj.imageWidgetConfig = Object.assign({}, this.imageWidgetConfig);
      // obj.imageWidgetConfig.col = e.layerX;
      // obj.imageWidgetConfig.row = e.layerY;
      // this.editings[this.currentPageActive].editings.images.push(obj);

      // this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'images' });
      // this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });

      // this.onClick.emit(this.selectedElement);
      // this.saveEditingsData();


      var UrlString;
      this.pdfService.getBase64ImageFromUrl2(this.selectedSignature).then((result) => {
        UrlString = this.domSanitizer.bypassSecurityTrustResourceUrl(result);
        let obj: any = {};
        obj.id = this.count++;
        obj.url = UrlString;
        obj.dateTime = this.datePipe.transform(new Date(), "MM/dd/yyyy hh:mm a");
        obj.imageWidgetConfig = Object.assign({}, this.imageWidgetConfig);
        obj.imageWidgetConfig.col = e.layerX;
        obj.imageWidgetConfig.row = e.layerY;

        console.log(obj);

        this.editings[this.currentPageActive].editings.images.push(obj);
        this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'images' });
        this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });
        this.onClick.emit(this.selectedElement);

        this.saveEditingsData();
      });
    }
    else {
      var UrlString;
      this.pdfService.getBase64ImageFromUrl(this.uplodedImage).then((result) => {
        UrlString = this.domSanitizer.bypassSecurityTrustResourceUrl(result);
        let obj: any = {};
        obj.id = this.count++;
        obj.url = UrlString;
        obj.imageWidgetConfig = Object.assign({}, this.imageWidgetConfig);
        obj.imageWidgetConfig.col = e.layerX;
        obj.imageWidgetConfig.row = e.layerY;
        this.editings[this.currentPageActive].editings.images.push(obj);
        this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'images' });
        this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });
        this.onClick.emit(this.selectedElement);

        this.saveEditingsData();
      });
    }
  }

  public removeImage(e: MouseEvent) {
    let temp = (e.srcElement || e.target) as HTMLElement;

    for (let i = 0; i < this.editings[this.currentPageActive].editings.images.length; i++) {
      if (this.editings[this.currentPageActive].editings.images[i]) {
        if (parseInt(temp.parentElement.parentElement.id) == this.editings[this.currentPageActive].editings.images[i].id) {

          this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.images[i], 'EditingID': this.editings[this.currentPageActive].editings.images[i].id, 'EditingType': 'images' });
          this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.images[i].id, 'UndoOperationFor': 'Delete' });

          this.editings[this.currentPageActive].editings.images.splice(i, 1);
          this.onClick.emit();
        }
      }
      else {
        break;
      }
    }

    console.log(this.editings);
    this.saveEditingsData();
  }

  public onImageCompete(e: INgWidgetEvent, data: number) {
    for (let i = 0; i < this.editings[this.currentPageActive].editings.images.length; i++) {
      if (this.editings[this.currentPageActive].editings.images[i]) {
        if (data == this.editings[this.currentPageActive].editings.images[i].id) {
          Object.assign(this.editings[this.currentPageActive].editings.images[i].imageWidgetConfig, e);
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public createSignature(e: MouseEvent) {
    let obj: any = {};
    obj.id = this.count++;
    obj.myStyles = { 'position': 'absolute', 'top': (e.layerY) + 'px', 'left': (e.layerX) + 'px', 'width': '350px', 'height': '150px', 'transform': 'translate(0px, 0px) !important' };
    obj.signature = obj.id;
    this.editings[this.currentPageActive].editings.signatures.push(obj);

    this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'signatures' });
    this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });
    this.saveEditingsData();
    this.onClick.emit(this.selectedElement);
  }

  public removeSignature(e) {
    let temp = (e.srcElement || e.target) as HTMLElement;

    for (let i = 0; i < this.editings[this.currentPageActive].editings.signatures.length; i++) {
      if (this.editings[this.currentPageActive].editings.signatures[i]) {
        if (parseInt(temp.parentElement.id) == this.editings[this.currentPageActive].editings.signatures[i].id) {
          this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.signatures[i], 'EditingID': this.editings[this.currentPageActive].editings.signatures[i].id, 'EditingType': 'signatures' });
          this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.signatures[i].id, 'UndoOperationFor': 'Delete' });

          this.editings[this.currentPageActive].editings.signatures.splice(i, 1);
          this.onClick.emit();
        }
      }
      else {
        break;
      }
    }

    this.saveEditingsData();
  }

  public onSignatureDragEnd(e, index) {

    this.editings[this.currentPageActive].editings.signatures.forEach((el, ind) => {
      if (ind == index) {

        let left = this.editings[this.currentPageActive].editings.signatures[index].myStyles.left.split('p')[0];
        let top = this.editings[this.currentPageActive].editings.signatures[index].myStyles.top.split('p')[0];

        this.editings[this.currentPageActive].editings.signatures[index].myStyles.left = parseInt(left) + parseInt(e.x) + 'px';
        this.editings[this.currentPageActive].editings.signatures[index].myStyles.top = parseInt(top) + parseInt(e.y) + 'px';

        this.saveEditingsData();

        this.editings[this.currentPageActive].editings.signatures[index].myStyles.left = left + 'px';
        this.editings[this.currentPageActive].editings.signatures[index].myStyles.top = top + 'px';
      }
    });
  }

  public drawComplete(event) {
    this.signaturePads.forEach((el: any, ind) => {
      if (el.options.currentPage == this.currentPageActive) {
        if (el.options.itemIndex == event)
          this.editings[this.currentPageActive].editings.signatures[event].signature = el.toDataURL();
      }
    });
    this.onClick.emit(this.selectedElement);
    this.saveEditingsData();
  }

  public drawStart() {
  }

  public copyDocumentUrl(docData) {
    console.log(docData);
    this.spService.copyToClipBoard(docData, 'url');
    this.msgs = [];
    this.msgs.push({ severity: Constants.successSeverity, summary: Constants.successSummary, detail: Constants.copiedToClipboard });
  }

  public addArrow(e: MouseEvent, Data) {
    let url: String;
    if (Data == "Arrow") {
      url = this.selectedArrows;
    }
    else {
      url = this.selectedCallouts;
    }

    this.arrowStyles = { 'height': '100%', 'width': '100%', 'float': 'left' };
    let obj: any = {};
    obj.id = this.count++;
    obj.url = url;
    obj.arrowStyles = this.arrowStyles;
    obj.imageWidgetConfig = Object.assign({}, this.imageWidgetConfig);
    obj.imageWidgetConfig.col = e.layerX;
    obj.imageWidgetConfig.row = e.layerY;
    obj.imageWidgetConfig.sizey = 50;
    this.editings[this.currentPageActive].editings.arrows.push(obj);

    this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'arrows' });
    this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });

    this.onClick.emit(this.selectedElement);
    setTimeout(() => {
      this.selectedElement = Data;
    }, 500);

    this.saveEditingsData();
    this.setRotaedEvent();

  }

  public removeArrow(e: MouseEvent) {
    let temp = (e.srcElement || e.target) as HTMLElement;

    for (let i = 0; i < this.editings[this.currentPageActive].editings.arrows.length; i++) {
      if (this.editings[this.currentPageActive].editings.arrows[i]) {
        if (parseInt(temp.parentElement.parentElement.id) == this.editings[this.currentPageActive].editings.arrows[i].id) {
          this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.arrows[i], 'EditingID': this.editings[this.currentPageActive].editings.arrows[i].id, 'EditingType': 'arrows' });
          this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.arrows[i].id, 'UndoOperationFor': 'Delete' });

          this.editings[this.currentPageActive].editings.arrows.splice(i, 1);
          this.onClick.emit();
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public onArrowCompete(e: INgWidgetEvent, data: number) {
    for (let i = 0; i < this.editings[this.currentPageActive].editings.arrows.length; i++) {
      if (this.editings[this.currentPageActive].editings.arrows[i]) {
        if (data == this.editings[this.currentPageActive].editings.arrows[i].id) {
          Object.assign(this.editings[this.currentPageActive].editings.arrows[i].imageWidgetConfig, e);
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public getImagePos(e: MouseEvent) {
    this.arrowStyles = {};
    var div = e.target as HTMLElement;
    var data = div.children[6];
    var divStyle = data.getAttribute("style").split(';');
    for (var i = 0; i < divStyle.length; i++) {
      if (divStyle[i].indexOf('transform') != -1) {
        this.arrowStyles.height = '100%';
        this.arrowStyles.width = '100%';
        this.arrowStyles.float = 'left';
        this.arrowStyles.transform = divStyle[i].split(':')[1];
        this.setRotatedStyle(parseInt(div.id), this.arrowStyles);
      }
    }
  }

  public setRotatedStyle(Id: number, style: any) {
    for (let i = 0; i < this.editings[this.currentPageActive].editings.arrows.length; i++) {
      if (this.editings[this.currentPageActive].editings.arrows[i]) {
        if (Id == this.editings[this.currentPageActive].editings.arrows[i].id) {
          this.editings[this.currentPageActive].editings.arrows[i].arrowStyles = style;
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public setRotaedEvent() {
    setTimeout(() => {
      var rotateCSS;
      $('.arrowDiv').draggable({
        handle: '.roatedme',
        opacity: 0.001,
        helper: 'clone',
        drag: (event) => {

          var // get center of div to rotate
            pw = event.target,
            pwBox = pw.getBoundingClientRect(),
            center_x = (pwBox.left + pwBox.right) / 2,
            center_y = (pwBox.top + pwBox.bottom) / 2,
            // get mouse position
            mouse_x = event.pageX,
            mouse_y = event.pageY,
            radians = Math.atan2(mouse_x - center_x, mouse_y - center_y),
            degree = Math.round((radians * (180 / Math.PI) * -1) + 100);

          rotateCSS = 'rotate(' + (degree + 170) + 'deg)';

          $(event.target.children[6]).css({
            '-moz-transform': rotateCSS,
            '-webkit-transform': rotateCSS,
            '-o-transform': rotateCSS,
            'transform': rotateCSS
          });
        }
      });
    }, 5000);
  }

  /*Stamps*/
  public addStamps(e: MouseEvent) {

    let obj: any = {};
    obj.id = this.count++;
    obj.companyname = this.selectedStamp.CompanyName;
    obj.username = this.spService.userName;
    obj.date = this.datePipe.transform(new Date(), "MM/dd/yyyy hh:mm a");
    obj.type = this.selectedStamp.SName;
    obj.imageWidgetConfig = Object.assign({}, this.stampWidgetConfig);
    obj.imageWidgetConfig.col = e.layerX;
    obj.imageWidgetConfig.row = e.layerY;
    obj.style = { 'color': this.selectedStamp.TextColor, 'width': '100%', 'height': '70%', 'text-align': 'center', 'font-size': '30px', 'font-weight': 'bold', 'display': 'table-cell', 'vertical-align': 'middle' };
    this.editings[this.currentPageActive].editings.stamps.push(obj);

    this.undoEditings.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'EditingType': 'stamps' });
    this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': obj.id, 'UndoOperationFor': 'Insert' });

    this.onClick.emit(this.selectedElement);
    setTimeout(() => {
      this.selectedElement = "Stamps";
    }, 500);

    this.saveEditingsData();
  }

  public removeStamps(e: MouseEvent) {
    let temp = (e.srcElement || e.target) as HTMLElement;

    for (let i = 0; i < this.editings[this.currentPageActive].editings.stamps.length; i++) {
      if (this.editings[this.currentPageActive].editings.stamps[i]) {
        if (parseInt(temp.parentElement.parentElement.id) == this.editings[this.currentPageActive].editings.stamps[i].id) {
          this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.stamps[i], 'EditingID': this.editings[this.currentPageActive].editings.stamps[i].id, 'EditingType': 'stamps' });
          this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.stamps[i].id, 'UndoOperationFor': 'Delete' });

          this.editings[this.currentPageActive].editings.stamps.splice(i, 1);
          this.onClick.emit();
        }
      }
      else {
        break;
      }
    }

    this.saveEditingsData();
  }

  public onStampsCompete(e: INgWidgetEvent, data: number) {
    for (let i = 0; i < this.editings[this.currentPageActive].editings.stamps.length; i++) {
      if (this.editings[this.currentPageActive].editings.stamps[i]) {
        if (data == this.editings[this.currentPageActive].editings.stamps[i].id) {
          Object.assign(this.editings[this.currentPageActive].editings.stamps[i].imageWidgetConfig, e);
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
  }

  public showDialog(Id, e: MouseEvent, Data) {
    var newText = "";
    if (Data)
      newText = Data;
    else
      newText = "";
    this.currentEditId = parseInt(Id);
    this.currentSEditMouseEvent = e;
    this.display = true;
    this.editedText = newText;
  }

  public saveData(data: HTMLElement) {
    var ele = data.getElementsByClassName('fr-element')[0].innerHTML;
    ele = ele.replace(/'/g, "&#39;");
    for (let i = 0; i < this.editings[this.currentPageActive].editings.arrows.length; i++) {
      if (this.editings[this.currentPageActive].editings.arrows[i]) {
        if (this.currentEditId == this.editings[this.currentPageActive].editings.arrows[i].id) {
          this.editings[this.currentPageActive].editings.arrows[i].text = ele;
          this.editedText = null;
        }
      }
      else {
        break;
      }
    }
    this.saveEditingsData();
    this.display = false;
  }

  public closeData() {
    this.editedText = null;
    this.display = false;
  }
  public closeData2(e: Event) {
    this.editedText = null;
    this.display = false;
  }

  public bindHtml(data): SafeHtml {
    if (data)
      return this.domSanitizer.bypassSecurityTrustHtml(data);
    else
      return;
  }

  public selected(e: Event, itemId: number, type: string) {
    var Data = null;
    let menuLinks: HTMLCollection = document.getElementsByClassName('selected');
    for (var i = 0; i < menuLinks.length; i++) {
      menuLinks[i].classList.remove('selected');
    }

    if (type == 'signatures') {
      Data = e.target as HTMLElement;
      Data.parentElement.parentElement.classList.add('selected');
    }
    else {
      Data = e.target as HTMLElement;
      Data.classList.add('selected');
    }

    this.deletedEle = e;
    this.deletedEleId = itemId;
    this.deletedEleType = type;
    this.isDeleted = true;
  }

  public scrolltop() {
    console.log("Hi.....");
  }

  public toggleSidebar()
  {
    console.log("inside toggle classs.....");
    let divSidebar = document.getElementsByClassName('informationdiv');
    
    
    //$("#sidebar").toggleClass("collapsed");
    console.log("complete toggle classs.....");
				//$("#content").toggleClass("col-md-12 col-md-9");
				
				return false;
  }

  @HostListener('window:keydown', ['$event'])
  public KeyboardEvent(event: KeyboardEvent) {
    if (this.isDeleted) {
      if (event.keyCode == 46) {
        switch (this.deletedEleType) {
          case "textEditings":
            for (let i = 0; i < this.editings[this.currentPageActive].editings.textEditings.length; i++) {
              if (this.editings[this.currentPageActive].editings.textEditings[i]) {
                if (this.deletedEleId == this.editings[this.currentPageActive].editings.textEditings[i].id) {
                  this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.textEditings[i], 'EditingID': this.editings[this.currentPageActive].editings.textEditings[i].id, 'EditingType': 'textEditings' });
                  this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.textEditings[i].id, 'UndoOperationFor': 'Delete' });
                  this.editings[this.currentPageActive].editings.textEditings.splice(i, 1);
                }
              }
              else {
                break;
              }
            }
            break;
          case "rectangleouts":
            for (let i = 0; i < this.editings[this.currentPageActive].editings.rectangleouts.length; i++) {
              if (this.editings[this.currentPageActive].editings.rectangleouts[i]) {
                if (this.deletedEleId == this.editings[this.currentPageActive].editings.rectangleouts[i].id) {
                  this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.rectangleouts[i], 'EditingID': this.editings[this.currentPageActive].editings.rectangleouts[i].id, 'EditingType': 'rectangleouts' });
                  this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.rectangleouts[i].id, 'UndoOperationFor': 'Delete' });
                  this.editings[this.currentPageActive].editings.rectangleouts.splice(i, 1);
                }
              }
              else {
                break;
              }
            }
            break;
          case "images":
            for (let i = 0; i < this.editings[this.currentPageActive].editings.images.length; i++) {
              if (this.editings[this.currentPageActive].editings.images[i]) {
                if (this.deletedEleId == this.editings[this.currentPageActive].editings.images[i].id) {
                  this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.images[i], 'EditingID': this.editings[this.currentPageActive].editings.images[i].id, 'EditingType': 'images' });
                  this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.images[i].id, 'UndoOperationFor': 'Delete' });
                  this.editings[this.currentPageActive].editings.images.splice(i, 1);
                }
              }
              else {
                break;
              }
            }
            break;
          case "signatures":
            for (let i = 0; i < this.editings[this.currentPageActive].editings.signatures.length; i++) {
              if (this.editings[this.currentPageActive].editings.signatures[i]) {
                if (this.deletedEleId == this.editings[this.currentPageActive].editings.signatures[i].id) {
                  this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.signatures[i], 'EditingID': this.editings[this.currentPageActive].editings.signatures[i].id, 'EditingType': 'signatures' });
                  this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.signatures[i].id, 'UndoOperationFor': 'Delete' });
                  this.editings[this.currentPageActive].editings.signatures.splice(i, 1);
                }
              }
              else {
                break;
              }
            }
            break;
          case "arrows":
            for (let i = 0; i < this.editings[this.currentPageActive].editings.arrows.length; i++) {
              if (this.editings[this.currentPageActive].editings.arrows[i]) {
                if (this.deletedEleId == this.editings[this.currentPageActive].editings.arrows[i].id) {
                  this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.arrows[i], 'EditingID': this.editings[this.currentPageActive].editings.arrows[i].id, 'EditingType': 'arrows' });
                  this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.arrows[i].id, 'UndoOperationFor': 'Delete' });
                  this.editings[this.currentPageActive].editings.arrows.splice(i, 1);
                }
              }
              else {
                break;
              }
            }
            break;
          case "Stamps":
            for (let i = 0; i < this.editings[this.currentPageActive].editings.stamps.length; i++) {
              if (this.editings[this.currentPageActive].editings.stamps[i]) {
                if (this.deletedEleId == this.editings[this.currentPageActive].editings.stamps[i].id) {
                  this.deletedEditings.push({ 'CurrentPage': this.currentPageActive, 'DeletedObj': this.editings[this.currentPageActive].editings.stamps[i], 'EditingID': this.editings[this.currentPageActive].editings.stamps[i].id, 'EditingType': 'stamps' });
                  this.undoOperations.push({ 'CurrentPage': this.currentPageActive, 'EditingID': this.editings[this.currentPageActive].editings.stamps[i].id, 'UndoOperationFor': 'Delete' });
                  this.editings[this.currentPageActive].editings.stamps.splice(i, 1);
                }
              }
              else {
                break;
              }
            }
            break;
        }
        this.saveEditingsData();
        var el = document.getElementsByClassName('pdfCanvas');
        this.selectedElement = 'None';
        var canvasData = el[this.currentPageActive] as HTMLCanvasElement;
        canvasData.click();
        this.isDeleted = false;
      }
    }
  }

  public saveEditingsData() {
    this.pdfService.updateDocumentData(this.editings).subscribe((res) => {
      if (this.spService.checkDocumentStatus == 'checkin') {
	      this.graphService.checkOutCheckIn('checkout', '','').then((res1) => {
	        this.spService.checkDocumentStatus = 'checkout';
	        console.log(res1, 'IBSERT RESPONSE');
	      }).catch(err => {
	      console.log('checkOutError', err);
	    });
	  }     

    }, err => {
      this.showError(err);
    });
  }

  public showError(err) {
    this.msgs = [];
    this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: err.toString() });
  }

  public closeModal() {
    this.display = false;
  }

  public largepdffile() {
    this.router.navigate(['/onedrive']);
  }

}
