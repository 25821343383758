import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import 'froala-editor/js/plugins.pkgd.min.js';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExternalLinkAlt, faFolderPlus, faFolder } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle, faEdit, faFilePdf, faFolderOpen } from '@fortawesome/free-regular-svg-icons';

import { AppRoutingModule } from './app-routing.module';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { HomeComponent } from './home/home.component';
import { AlertsComponent } from './alerts/alerts.component';

import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { MsalModule, MsalInterceptor, MsalConfig } from '@azure/msal-angular';

import { BlockUIModule } from 'ng-block-ui';

import { PdfEditorComponent } from './pdf-editor/pdf-editor.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { TextEditorComponent } from './text-editor/text-editor.component';

import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { DragDropModule } from 'primeng/dragdrop';
import { PanelModule } from 'primeng/panel';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { DataGridModule } from 'primeng/datagrid';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { GrowlModule } from 'primeng/growl';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TabViewModule } from 'primeng/tabview';
import { ListboxModule } from 'primeng/listbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import {ProgressBarModule} from 'primeng/progressbar';

import { NgDraggableWidgetModule } from 'ngx-draggable-widget';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { SignaturePadModule } from 'angular2-signaturepad';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AngularDraggableModule } from 'angular2-draggable';
import { SafePipe } from './safe.pipe';
import { LoginComponent } from './login/login.component';
import { SPFxECBComponent } from './spfx-ecb/spfx-ecb.component';
import { Constants } from './app.constants';
import { BlockTemplateComponent } from './block-template.component';


library.add(faExternalLinkAlt);
library.add(faUserCircle, faEdit, faFilePdf, faFolder, faFolderOpen, faFolderPlus);

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    AlertsComponent,
    PdfEditorComponent,
    PdfViewerComponent,
    TextEditorComponent,
    SafePipe,
    LoginComponent,
    SPFxECBComponent,
    BlockTemplateComponent 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    MsalModule.forRoot({      
      clientID: Constants.OAuthSettings.appId,
      cacheLocation: 'localStorage',
    }),
    HttpModule,
    HttpClientModule,
    ToolbarModule,
    ButtonModule,
    PaginatorModule,
    DragDropModule,
    InputTextModule,
    AutoCompleteModule,
    DataViewModule,
    DialogModule,
    PanelModule,
    EditorModule,
    FileUploadModule,
    DropdownModule,
    TableModule,
    DataGridModule,
    SplitButtonModule,
    ColorPickerModule,
    OverlayPanelModule,
    GrowlModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    TabViewModule,
    ListboxModule,
    ConfirmDialogModule,
    NgDraggableWidgetModule,
    TooltipModule,
    SignaturePadModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    BlockUIModule.forRoot({
      //template: BlockTemplateComponent
    }),
    AngularDraggableModule,
    ProgressBarModule

  ],
  entryComponents: [
    BlockTemplateComponent // Make sure to add it to the entry components
  ],
  providers: [ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
