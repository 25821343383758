import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { PdfEditorComponent } from './pdf-editor/pdf-editor.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { SPFxECBComponent } from './spfx-ecb/spfx-ecb.component';


const routes: Routes = [
    { path: 'home', component: LoginComponent },
    { path: 'onedrive', component: HomeComponent },
    { path: 'SPFx_ECB', component: SPFxECBComponent },
    { path: 'pdfmarkup', component: PdfEditorComponent, canActivate: [AuthGuardService] },    
    { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }