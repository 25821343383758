export class SPDocumentOneDrive {
    public id: string;
    public lastModifiedBy: any;
    public fileSystemInfo: any;
    public name: string;
    public createdBy: any;
    public webUrl: string;
    public size: number;
    public documentFileSize: string;    
    public parentReference : any;

    constructor(obj?: any) {
        this.id = obj && obj.id || '';
        this.lastModifiedBy = obj && obj.lastModifiedBy || null;
        this.fileSystemInfo = obj && obj.fileSystemInfo || null;
        this.name = obj && obj.name || '';
        this.createdBy = obj && obj.createdBy || null;
        this.webUrl = obj && obj.webUrl || '';
        this.size = obj && obj.size || 0;      
        this.documentFileSize = obj && obj.documentFileSize || '';  
        this.parentReference = obj && obj.parentReference || null;  
    }
}