import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { GraphService } from '../services/graph.service';

import { Drives } from '../models/event';
import { AlertsService } from '../services/alerts.service';
import { CommonService } from '../services/common.service';

import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { Router, ActivatedRoute } from '@angular/router';
import { SpServiceService } from '../services/sp-service.service';
import { Message } from 'primeng/components/common/api';

import { Constants } from '../app.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  public siteUrl = environment.siteUrl;
  private events: Drives[];
  public docData: any = [];
  public recentEditedDocData: any = [];
  public msgs: Message[] = [];
  public reactivelicense: boolean = false;
  public tenantName: string;
  public tenantId: string;
  public userEmailAddress: any = '';
  public admindetails: boolean = false;
  public userDetails: any = [];
  public folders: any = [];

  public AllDocument: any[] = [];

  public page = 1;
  public pageSize = 10;
  public collectionSize = 0;

  public page2 = 1;
  public pageSize2 = 5;
  public collectionSize2 = 0;

  public isAdminUser: boolean = false;

  constructor(
    private authService: AuthService,
    private graphService: GraphService,
    public alertsService: AlertsService,
    private commonService: CommonService,
    private router: Router,
    public spService: SpServiceService,
    private _ngZone: NgZone) {
    this.spService.isShareandDownload = false;
    this.checkUserIsRegistered();
    // this.getDocuments();     
    
  }

  ngOnInit() {
  }

  checkUserIsRegistered() {
    this.blockUI.start('Please Wait!!!');    
    this.blockUI.update('File assembled - All complete.');    
    var modal = document.getElementById('divSaveComplete');            
  
    // this.graphService.getTenantName().then((Data) => {
    console.log(this.spService.userLoginName);
    var tenant = this.spService.userLoginName.split('@')[1].split('.')[0];
    //var weburl = 'https://' + tenant + '.sharepoint.com';
    var weburl = localStorage.getItem("hostWebURL");
    this.commonService.emitWebUrl(weburl);
    this.tenantId = this.spService.tenantID;
    //this.spService.sharepointrootdetails = Data;
    this.tenantName = tenant;
    console.log("tdata", this.tenantName);
    this.alertsService.removeall();
    this.commonService.checkUserIsRegistered(this.tenantName).then((result) => {      
      console.log(result);
      if (result == "false") {
        this.spService.isTenantRegister = "false";
        //this.spService.tenantID =  'undefined';
        this.userEmailAddress = this.spService.userLoginName;
        this.reactivelicense = false;
        if (this.blockUI) {
          this.blockUI.stop();
          //modal.style.display = "none";
        }
        this._ngZone.run(() => {
          console.log('Outside Done!');
        });
      }
      else {
        this.spService.tenantID = result[0].TenantId;
        this.commonService.emitTenantId(result[0].TenantId);
        this.userEmailAddress = this.spService.userLoginName;
        this.spService.checkIsTenantisRegister(this.spService.tenantID).subscribe((res) => {
          console.log(res);

          if (res[0].Remainingdays < 0) {
            this.spService.isTenantRegister = "false";
            this.userDetails = res[0];
            this.spService.adminuserdetails = res[0];
            this.reactivelicense = true;
          }
          else {
            this.userDetails = res[0];
            this.spService.adminuserdetails = res[0];
            this.spService.isTenantRegister = "true";
            this.spService.PurchaseData = res[0];
            this.reactivelicense = false;

            this.getDocuments();
            this.getRecentEditedDocument();
          }
          if (this.blockUI) {
            this.blockUI.stop();
            //modal.style.display = "none";
          }

          this._ngZone.run(() => {
            console.log('Outside Done!');
          });
        }, err => {
          this.showError(err);
          if (this.blockUI) {
            this.blockUI.stop();
            modal.style.display = "none";
          }
        });

        this.spService.getTenantAdmins(this.spService.tenantID).subscribe((resData: any) => {
          if (resData) {
            for (let i = 0; i < resData.length; i++) {
              if (this.userEmailAddress == resData[i].UserLoginName) {
                this.isAdminUser = true;
                break;
              }
            }
          }
        }, errData => {
          this.showError(errData);
        });
      }
    }).catch((err) => {
      console.log(err);
      this.spService.isTenantRegister = "false";
      //this.alertsService.add('Tenant is not registered', 'Please registered Your tenant first.');
      if (this.blockUI) {
        this.blockUI.stop();
        modal.style.display = "none";
      }

    });
    // }).catch((err) => {
    //   console.log(err);
    //   this.removeLocalstorageItem();
    //   this.alertsService.add('Error: accessDenied', JSON.stringify(err["message"] + " User need root site permission. Please contact your administrator", null, 2));
    //   if (this.blockUI) {
    //     this.blockUI.stop();
    //   }
    //   setTimeout(() => {
    //     this.authService.authenticated = false;
    //     this.router.navigate(['/home']);
    //   }, 2000);
    // });
  }

  getDocuments() {
    this.blockUI.start('Please Wait....');
    //this.blockUI.start('File assembled - All complete');
    this.graphService.getOneDriveData().then((result) => {
      var data = []
      for (var i = 0; i < result.value.length; i++) {
        if (result.value[i].file) {
          if (result.value[i].file.mimeType.indexOf('pdf') > -1) {
            data.push(result.value[i]);
          }
        }
        else if (result.value[i].folder) {
          data.push(result.value[i])
        }
      }

      this.commonService.emitOneDriveWebUrl(data[0].webUrl);

      this.docData = data;
      console.log(this.docData);
      this.collectionSize = this.docData.length;
      this.folders = [];
      this.folders.push({ 'name': 'Home', 'id': '', 'driveId': '' })
      this._ngZone.run(() => {
        console.log('Outside Done!');
      });
      if (this.blockUI) {
        this.blockUI.stop();
      }
      //console.log(events.siteCollection.hostname.split('.')[0]);  

    }).catch(err => {
      console.log(err);
      this.alertsService.add('Error: notAllowed', JSON.stringify(err["message"], null, 2));
      if (this.blockUI) {
        this.blockUI.stop();
      }      
    });
  }

  getRecentEditedDocument() {
    //https://graph.microsoft.com/v1.0/me/drive/recent?orderby=lastModifiedDateTime%20desc

    //this.blockUI.start('Please Wait..');
    this.blockUI.start('File assembled - All complete');
    var modal = document.getElementById('divSaveComplete');            
    modal.style.display = "block";
    console.log("divSaveComplete", modal); 
    console.log('getRecentEditedDocument');
    this.graphService.getRecentEditedPDF().then((result) => {
      var data = []
          var count = 0;
      this.commonService.getPDFMasterTableDetails(this.tenantId).then((MasterDetails :any)=>{
        if(MasterDetails == "false"){
          
          for (var i = 0; i < result.value.length; i++) {
            if (result.value[i].file.mimeType.indexOf('pdf') > -1) {
              data.push(result.value[i]);
              count++;
              if (count == 5) {
                break;
              }
            }
          }
          if (this.blockUI) {
            this.blockUI.stop();
            modal.style.display = "none";
          }
        }else{
          
          for (var i = 0; i < result.value.length; i++) {
            for(var j = 0;j<MasterDetails.length;j++){
              if(result.value[i].webUrl ===  MasterDetails[j].WebServerRelativeUrl){
                if (result.value[i].file.mimeType.indexOf('pdf') > -1) {
                  data.push(result.value[i]);
                  count++;
                  if (count == 5) {
                    break;
                  }
                }
              }
            }
            
          }
          if (this.blockUI) {
            this.blockUI.stop();
            modal.style.display = "none";
          }
        }
        
      }).catch(err => {
        console.log(err);
        if (this.blockUI) {
          this.blockUI.stop();
          modal.style.display = "none";
        }
      });
      

      //this.commonService.emitOneDriveWebUrl(data[0].webUrl);

      this.recentEditedDocData = data;
      console.log(this.recentEditedDocData);
      this.collectionSize2 = this.recentEditedDocData.length;
      // this.folders = [];
      // this.folders.push({ 'name': 'Home', 'id': '', 'driveId': '' })
      this._ngZone.run(() => {
        console.log('Outside Done!');
      });
      
      //console.log(events.siteCollection.hostname.split('.')[0]);  

    }).catch(err => {
      console.log(err);
      if (this.blockUI) {
        this.blockUI.stop();
      }
    });
  }

  get oneDriveData(): any {
    return this.docData
      .map((docData, i) => ({ id: i + 1, ...docData }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  get recentEditedDocDatas(): any {
    return this.recentEditedDocData
      .map((recentEditedDocData, i) => ({ id: i + 1, ...recentEditedDocData }))
      .slice((this.page2 - 1) * this.pageSize2, (this.page2 - 1) * this.pageSize2 + this.pageSize2);
  }

  openFolder(driveId, folderId, foldername, index) {
    console.log("driveId", driveId)
    console.log("folderId", folderId)
    this.graphService.getFolderData(driveId, folderId)
      .then((events) => {
        console.log(event);

        if (index) {
          this.folders.splice(index, this.folders.length - (index));
        }

        this.folders.push({ 'name': foldername, 'id': folderId, 'driveId': driveId })
        this.docData = events;
        this.collectionSize = this.docData.length;
        //console.log(events.siteCollection.hostname.split('.')[0]);  
        if (this.blockUI) {
          this.blockUI.stop();
        }
      }).catch(err => {
        console.log(err);
        if (this.blockUI) {
          this.blockUI.stop();
        }
      });
  }

  openInEditor(guid: string) {
    this.router.navigate(['/pdfmarkup'], { queryParams: { docid: guid, onedrive: true,tenantid:this.tenantId } });
  }

  openrecentDocInEditor(driveid: string, guid: string, path: string) {
    this.router.navigate(['/pdfmarkup'], { queryParams: { driveid: driveid, docid: guid, onedrive: true,tenantid:this.tenantId } });
  }

  public showError(err) {
    this.msgs = [];
    this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: err.toString() });
  }

  public copyTenant(docData) {
    this.spService.copyToClipBoard(docData, 'data');
    this.msgs = [];
    this.msgs.push({ severity: 'success', summary: 'Success Message', detail: Constants.copiedToClipboard });
  }

  public contactAdmin() {
    this.admindetails = true;
  }

  public handleClick() {
    if (this.spService.tenantID) {
      //window.open('https://sohodragon.nyc/pdfeditor/?registration=true&tenant=' + this.spService.tenantID + '&tname=' + this.tenantName + '&username=' + this.userEmailAddress, '_blank');
      window.open('https://sohodragon.nyc/pdfeditor/?registration=true&tenant=' + this.spService.tenantID + '&tname=' + this.tenantName);
    }
    else {
      window.open('https://sohodragon.nyc/pdfeditor/?registration=true&tname=' + this.tenantName + '&username=' + this.userEmailAddress, '_blank');
    }

  }

  // public makeNewAdmin() {
  //   window.open('https://sohodragon.nyc/pdfeditor/?changeadmin=true&tenant=' + this.spService.tenantID + '&tname=' + this.tenantName + '&username=' + this.userEmailAddress, '_blank');
  // }

  public gotoAdmin() {
    window.open('https://sohodragon.nyc/pdfeditor/?admin=only', '_blank');
  }

  public closeModal() {
    this.admindetails = false;
  }

  public removeLocalstorageItem() {
    localStorage.removeItem("DocDataOnedrive");
    localStorage.removeItem("sharepointDriveInfo");
    localStorage.removeItem("DocData");
    localStorage.removeItem("doclibraryname");
    localStorage.removeItem("documentLibraryName");
    localStorage.removeItem("documentFolder");
    localStorage.removeItem("tenantID");
    localStorage.removeItem("checkDocumentStatus");
    localStorage.removeItem("isTenantRegister");
    localStorage.removeItem("adminuserdetails");
    localStorage.removeItem("onedrive");
  }
}