import { Component, OnInit, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PdfService } from '../services/pdf.service';
import { SpServiceService } from '../services/sp-service.service';
import { SelectItem } from '../../../node_modules/primeng/api';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import * as jsPDF from '../../../node_modules/jspdf';
import html2canvas from 'html2canvas';
import { Message } from 'primeng/components/common/api';
import { ConfirmationService } from 'primeng/api';
import { Constants } from '../app.constants';

import { GraphService } from '../services/graph.service';
import { CommonService } from '../services/common.service';
import { map } from 'rxjs-compat/operator/map';
import { AuthService } from '../services/auth.service';
import { AlertsService } from '../services/alerts.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { BlockTemplateComponent } from '../block-template.component';

//below function is used to render multiple pdf pages asynchronously on canvas and to improve performance.
var asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};
declare var $: any;

@Component({
  selector: 'app-pdf-editor',
  templateUrl: './pdf-editor.component.html',
  styles: [Constants.PDFEditorCSS]
})
export class PdfEditorComponent implements OnInit {

  @BlockUI('save') blockUI: NgBlockUI;  
  blockTemplate = BlockTemplateComponent;  

  public globalRef: Constants = Constants;
  public currentPage: number = 0;
  public pdfSource: string;
  public totalPages: number = 1;
  public elementType: string;
  public uploadedImage: any;
  public pdfdocId: number;
  public actionData: string;
  public actions: SelectItem[];
  public selectedAction: any;
  public selectedAnnotations: any;
  public arrows: SelectItem[];
  public selectedArrow: any;
  public selectedArrows: any;

  public callouts: SelectItem[];
  public selectedCallout: any;
  public pagenumberModel: number;
  public sharedisplay: boolean = false;
  public userNames: any[];
  public user: any;
  public bodyText: string = '';
  public gotopage: number = 0;
  public msgs: Message[] = [];
  public editedPdfPages: any = [];
  public userDisabled: boolean = false;

  public ischnagenumber: boolean = false;
  public accessmsgs: Message[] = [];
  public stamps: SelectItem[];
  public stamp_model: any;
  public selectedStamp: any;

  public stampText: string = '';
  public stampTextColor: string= '#000000';
  public stampCompanyText: string = '';
  public sdisplay: boolean = false;
  public mdisplay: boolean = false;
  public stampTabIndex: number = 0;
  public selectedStamps: any[] = [];

  public isRegister: boolean = false;
  public redacts: any = [];
  public annotations: any = [];
  public saveanddownload: any = [];
  public manageOptions: any = [];
  public selectannotation: any = 'Annotations';
  public blockuipdf: boolean = false;
  public currentPageUndoEditings: any[] = [];
  public currentPageDeletedEditings: any[] = [];
  public currentPageUndoOperations: any[] = [];
  public isUndoDisabled: boolean = true;
  public isLoading: boolean = false;

  public driveid: string;
  public docid: string;

  public signatureUrl: string;
  public signatureModel: boolean = false;
  public selectedSignature: any;

  private downloadSubscription: Subscription;
  private sendEmailSubscription: Subscription;

  public checkBrowserType: string;
  public progressBarValue: number = 0;
  public totalEditedPage: number = 0;
  public editingPage: number = 0;

  //public isDisabledCallout = true;

  @ViewChild(PdfViewerComponent) public pdfViewerComponent: PdfViewerComponent;
  @ViewChild('pagenumber') private elementRef: ElementRef;

  constructor(
    private spService: SpServiceService,
    private pdfService: PdfService,
    private route: Router,
    private aroute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private graphService: GraphService,
    private commonService: CommonService,
    private authService: AuthService,
    private alertsService: AlertsService,
    private _ngZone: NgZone
  ) {

    this.sendEmailSubscription = this.commonService.sharelink.subscribe((res: any) => {
      if (res == 'send') {
        this.sendShareableLink();
      }
    });

    console.log("constructor called....");
    this.downloadSubscription = this.commonService.downloadPDF.subscribe((resDownloadData: any) => {
      if (resDownloadData == 'download') {
        this.downloadCurrentPage(resDownloadData);
      }
    });

    this.aroute.queryParams.subscribe((params) => {
      if (params.docid && !params.driveid) {
        this.graphService.getDocumentDownloadUrl(params.docid).then((res) => {
          this.spService.currentDocumentOnedrive = res;
          console.log('this.spService.currentDocumentOnedrive', this.spService.currentDocumentOnedrive);
          this.spService.onedrive = "true";
          this.spService.currentDocument.GUID = res["id"];
          this.isRegister = true;
          this.spService.isTenantRegister = "true";
          this.pdfSource = res["@microsoft.graph.downloadUrl"];
          //this.commonService.emitPDFSource(res["@microsoft.graph.downloadUrl"]);
        });
      }
      else if ((params.docid && params.driveid)) {
        window.scrollTo(0, 0)
        this.sharedisplay = false;
        this.driveid = params.driveid;
        this.docid = params.docid;
        this.graphService.getSharepointDocumentDownloadUrl(params.driveid, params.docid).then((res) => {
          this.spService.currentDocumentOnedrive = res;
          console.log(this.spService.currentDocumentOnedrive.parentReference.path)
          //Open sharepoint document from recently eidited document.
          if (this.spService.currentDocumentOnedrive.webUrl.indexOf('sites') > 0) {

            this.commonService.getSharepointFileGUID(res.webUrl,params.tenantid).then((resData: any) => {
              console.log(resData[0].DocumentGUID)
              this.spService.currentDocument.GUID = resData[0].DocumentGUID;

              var driveFolderData = this.spService.currentDocumentOnedrive.parentReference.path.split(':/')[1];
              this.spService.drivefolder = driveFolderData != undefined ? driveFolderData : 'Root';

              if (this.spService.drivefolder != 'Root') {
                var drivenamedata = this.spService.currentDocumentOnedrive.webUrl.split(this.spService.drivefolder)[0].split('/');
                var driverName = drivenamedata[drivenamedata.length - 2];
                this.spService.driveName = decodeURIComponent(driverName);
              }
              else {
                var drivenamedata = this.spService.currentDocumentOnedrive.webUrl.split('.pdf')[0].split('/');
                var driverName = drivenamedata[drivenamedata.length - 2];
                this.spService.driveName = decodeURIComponent(driverName);
              }

              this.graphService.checkOutCheckIn('checkout', params.driveid, params.docid).then((res) => {
                this.spService.checkDocumentStatus = 'checkout';
              }).catch((e) => {
                console.log('checkinerror', e)
                //this.route.navigate(['/onedrive']);
              });

              this.spService.onedrive = "true";
              this.isRegister = true;
              this.spService.isTenantRegister = "true";
              this.pdfSource = res["@microsoft.graph.downloadUrl"];

              var tenant = this.spService.userLoginName.split('@')[1].split('.')[0];
              var weburl = localStorage.getItem("hostWebURL");
              this.commonService.emitWebUrl(weburl);

              this.graphService.getOneDriveData().then((result) => {
                if (result.value.length > 0) {
                  this.commonService.emitOneDriveWebUrl(result.value[0].webUrl);
                }
              }).catch(err => {
                console.log(err);
              });
            }).catch((err) => {
              console.log(err);
            })
          }
          //Open onedrive document.
          else {
            var driveFolderData = this.spService.currentDocumentOnedrive.parentReference.path.split(':/')[1];
            this.spService.drivefolder = driveFolderData != undefined ? driveFolderData : 'Root';
            this.spService.driveName = 'OneDrive';
            this.spService.currentDocument.GUID = res["id"];
            this.spService.onedrive = "true";

            this.spService.onedrive = "true";
            this.isRegister = true;
            this.spService.isTenantRegister = "true";
            this.pdfSource = res["@microsoft.graph.downloadUrl"];

            var tenant = this.spService.userLoginName.split('@')[1].split('.')[0];
            var weburl = localStorage.getItem("hostWebURL");
            this.commonService.emitWebUrl(weburl);

            this.graphService.getOneDriveData().then((result) => {
              if (result.value.length > 0) {
                this.commonService.emitOneDriveWebUrl(result.value[0].webUrl);
              }
            }).catch(err => {
              console.log(err);
            });
          }
          //this.commonService.emitPDFSource(res["@microsoft.graph.downloadUrl"]);
        });
      }
      else {
        if (authService.authenticated) {
          this.openDocInPDFMarkUp(params);
        }
      }

    });
  }

  openDocInPDFMarkUp(params) {
    console.log("login.........2222", params)
    this.spService.checkIsTenantisRegister(params.tenantId).subscribe((res) => {
      this.spService.onedrive = "false";
      console.log(res);
      if (!res) {
        this.removeLocalstorageItem();
        this.spService.isTenantRegister = "false";
        this.spService.tenantID = params.tenantId;
        this.route.navigate(['/onedrive']);
      }
      else if (res[0]) {
        if (res[0].Remainingdays < 0) {
          this.removeLocalstorageItem();
          this.spService.isTenantRegister = "false";
          this.spService.tenantID = params.tenantId;
          this.route.navigate(['/onedrive']);
        }
        else {
          this.spService.adminuserdetails = res[0];
          this.spService.isTenantRegister = "true";
          this.spService.PurchaseData = res[0];
          this.spService.currentDocument = JSON.parse(params.docData);
          console.log(this.spService.currentDocument);

          this.ngOnInit();
          this.spService.sharepointDriveInfo = params.driveInfo.toString().split('?')[0];
          /*if (this.spService.currentDocument.CheckoutUser) {
            this.spService.checkDocumentStatus = "checkout"
          }
          else {
            this.spService.checkDocumentStatus = "checkin"
          }*/
          this.graphService.checkOutCheckIn('checkout', params.driveid, params.docid).then((res) => {
            this.spService.checkDocumentStatus = 'checkout';
          }).catch((e) => {
            console.log('checkinerror', e)
            //this.route.navigate(['/onedrive']);
          });

          this.spService.fileName = JSON.parse(params.docData)["FileLeafRef"];
          this.spService.userLoginName = params.userEmailAddress;
          this.spService.tenantID = params.tenantId;
          this.spService.userName = params.userName;
          
          this.spService.doclibraryName = params.libraryName;
          this.spService.folderName = this.getFolderName(JSON.parse(params.docData)["EncodedAbsUrl"]);

          var tenant = this.spService.userLoginName.split('@')[1].split('.')[0];
          //var weburl = 'https://' + tenant + '.sharepoint.com';
          var weburl = params.hostName;
          localStorage.setItem("hostWebURL", params.hostName);
          this.commonService.emitWebUrl(weburl);
          
          this.commonService.emitTenantId(this.spService.tenantID);
          this.graphService.getOneDriveData().then((result) => {
            if (result.value.length > 0) {
              this.commonService.emitOneDriveWebUrl(result.value[0].webUrl);
            }
          }).catch(err => {
            console.log(err);
          });

          this.graphService.getDocumentDownloadUrlForSharepoint(params).then(resData => {
            this.pdfSource = resData["@microsoft.graph.downloadUrl"];
            //this.commonService.emitPDFSource(this.pdfSource);
          }).catch(err => {
            console.error(err);
          })          
        }
      }
      else {
        this.spService.isTenantRegister = "false";
        this.route.navigate(['/onedrive']);
      }
    }, err => {
      this.isRegister = true;
      this.showError(err);
    });
  }

  ngOnInit() {
    if (this.spService.isTenantRegister || localStorage.getItem('isTenantRegister') == 'true') {
      this.isRegister = true;
      this.spService.isSaveDownloadClick = false;
      this.pagenumberModel = 1;

      this.checkBrowserType = this.checkBrowser();

      this.spService.getPDFEditorGraphicsCallouts(Constants.callouts).then((callout) => {
        let temp1 = callout;
        if (temp1 && temp1.length > 0) {
          this.callouts = temp1.map((ele) => {
            return { label: ele.Name.split('.')[0], value: ele.UrlData };
          });
        }
        //this.isDisabledCallout = false;  
      }).catch((err) => { console.log(err) });

      this.spService.getPDFEditorGraphicsAnnotations(Constants.annotations).then((annotation) => {
        let temp2 = annotation;
        if (temp2 && temp2.length > 0) {
          this.actions = temp2.map((ele) => {
            return { label: ele.Name.split('.')[0], value: ele.UrlData };
          });
        }
      }).catch((err) => { console.log(err) });

      this.spService.getPDFEditorGraphicsArrows(Constants.arrows).then((arrow) => {
        let temp3 = arrow;
        if (temp3 && temp3.length > 0) {
          this.arrows = temp3.map((ele) => {
            return { label: ele.Name.split('.')[0], value: ele.UrlData };
          });
        }
      }).catch((err) => { console.log(err) });

      this.getStamps();
    }

    
  }

  ngOnDestroy() {
    this.downloadSubscription.unsubscribe();
    this.sendEmailSubscription.unsubscribe();
  }

  public getStamps() {
    this.spService.getStamps().subscribe((res) => {
      var stampData = [];
      if (res.length > 0) {
        for (var i = 0; i < res.length; i++) {
          stampData.push({ 'name': res[i].SName, 'code': res[i].TextColor + "-" + res[i].CompanyName, 'id': res[i].ID });
        }
      }
      this.stamps = stampData;
    }, err => {
      this.showError(err);
    });
  }

  public deleteStamp(id: number) {
    alert('Stamp id: ' + id + ' deleted!');
  }
  public totalPdfPage(event) {
    this.totalPages = event;
  }

  public undoCurrentPage() {
    this.getCurrentPageUndoEditings();

    if (this.currentPageUndoOperations && this.currentPageUndoOperations.length > 0 && this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].UndoOperationFor == "Delete") {

      if (this.currentPageDeletedEditings && this.currentPageDeletedEditings.length > 0) {
        this.pdfViewerComponent.editings[this.currentPage].editings[this.currentPageDeletedEditings[this.currentPageDeletedEditings.length - 1].EditingType].push(this.currentPageDeletedEditings[this.currentPageDeletedEditings.length - 1].DeletedObj);

        let indexOfDeletedEdit = this.pdfViewerComponent.deletedEditings.findIndex(eleDelete => eleDelete.CurrentPage == this.currentPage && eleDelete.EditingID == this.currentPageDeletedEditings[this.currentPageDeletedEditings.length - 1].EditingID);
        this.pdfViewerComponent.deletedEditings.splice(indexOfDeletedEdit, 1);

        let indexOfCurrentPageDeletedEdit = this.currentPageDeletedEditings.findIndex(eleCurrentDelete => eleCurrentDelete.EditingID == this.currentPageDeletedEditings[this.currentPageDeletedEditings.length - 1].EditingID);
        this.currentPageDeletedEditings.splice(indexOfCurrentPageDeletedEdit, 1);

        let indexOfUndoOperation = this.pdfViewerComponent.undoOperations.findIndex(eleUndoOperation => eleUndoOperation.CurrentPage == this.currentPage && eleUndoOperation.EditingID == this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].EditingID && eleUndoOperation.UndoOperationFor == this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].UndoOperationFor);
        this.pdfViewerComponent.undoOperations.splice(indexOfUndoOperation, 1);

        let indexOfCurrentPageUndoOperation = this.currentPageUndoOperations.findIndex(eleCurrentUndoOperation => eleCurrentUndoOperation.EditingID == this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].EditingID && eleCurrentUndoOperation.UndoOperationFor == this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].UndoOperationFor);
        this.currentPageUndoOperations.splice(indexOfCurrentPageUndoOperation, 1);
      }
    }
    else if (this.currentPageUndoOperations && this.currentPageUndoOperations.length > 0 && this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].UndoOperationFor == "Insert") {

      if (this.currentPageUndoEditings && this.currentPageUndoEditings.length > 0) {

        let tempUndoEditings = this.pdfViewerComponent.editings[this.currentPage].editings[this.currentPageUndoEditings[this.currentPageUndoEditings.length - 1].EditingType].filter(ele => ele.id == this.currentPageUndoEditings[this.currentPageUndoEditings.length - 1].EditingID);

        if (tempUndoEditings.length > 0) {

          let indexOfCurrentPageEditings = this.pdfViewerComponent.editings[this.currentPage].editings[this.currentPageUndoEditings[this.currentPageUndoEditings.length - 1].EditingType].findIndex(eleCurrentEditing => eleCurrentEditing.id == tempUndoEditings[0].id);
          this.pdfViewerComponent.editings[this.currentPage].editings[this.currentPageUndoEditings[this.currentPageUndoEditings.length - 1].EditingType].splice(indexOfCurrentPageEditings, 1);

          let indexOfUndoEdit = this.pdfViewerComponent.undoEditings.findIndex(eleUndo => eleUndo.CurrentPage == this.currentPage && eleUndo.EditingID == tempUndoEditings[0].id);
          this.pdfViewerComponent.undoEditings.splice(indexOfUndoEdit, 1);

          let indexOfCurrentPageUndoEdit = this.currentPageUndoEditings.findIndex(eleCurrentUndo => eleCurrentUndo.EditingID == tempUndoEditings[0].id);
          this.currentPageUndoEditings.splice(indexOfCurrentPageUndoEdit, 1);

          let indexOfUndoOperation = this.pdfViewerComponent.undoOperations.findIndex(eleUndoOperation => eleUndoOperation.CurrentPage == this.currentPage && eleUndoOperation.EditingID == this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].EditingID && eleUndoOperation.UndoOperationFor == this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].UndoOperationFor);
          this.pdfViewerComponent.undoOperations.splice(indexOfUndoOperation, 1);

          let indexOfCurrentPageUndoOperation = this.currentPageUndoOperations.findIndex(eleCurrentUndoOperation => eleCurrentUndoOperation.EditingID == this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].EditingID && eleCurrentUndoOperation.UndoOperationFor == this.currentPageUndoOperations[this.currentPageUndoOperations.length - 1].UndoOperationFor);
          this.currentPageUndoOperations.splice(indexOfCurrentPageUndoOperation, 1);

          if (this.currentPageUndoEditings.length == 0) {
            this.isUndoDisabled = true;
          }
        }
      }
    }
    this.pdfService.updateDocumentData(this.pdfViewerComponent.editings);
  }

  public paginate($event) {
    this.currentPage = $event.page;
    this.pagenumberModel = this.currentPage + 1;
    this.gotopage = $event.page;
    this.getCurrentPageUndoEditings();
  }

  public downloadCurrentPage(actionData) {
    this.spService.isSaveDownloadClick = true;
    /* this._ngZone.run(() => {
      console.log('Outside Done!');
    }); */
    this.blockUI.start('Please make some coffee while we re assemble your PDF file. This will be done shortly... Page: 1/'+this.pdfViewerComponent.totalpagecount+'.');
    var modal = document.getElementById('domMessage');            
    modal.style.display = "block";
    console.log("dommessage", modal);

    if (this.spService.adminuserdetails.RemainingUsage == 0) {
      if (this.blockUI) {
        this.blockUI.stop();
        modal.style.display = "none";
      }
      this.spService.isSaveDownloadClick = false;
      this._ngZone.run(() => {
        console.log('Outside Done!downloadCurrentPage');
      });
      this.msgs = [];
      this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: Constants.monthlyLimitReached });
    }
    else {
      //this.progressBarValue = 1;      
      this.saveOrDownload(actionData);

    }
  }

  public saveOrDownload(actionData) {

    var promises = [];
    /* setTimeout(() => {
        this.pdfViewerComponent.loadAllPages().subscribe(result => {
          this.pdfViewerComponent.pdfPages = result;
          asyncForEach(this.pdfViewerComponent.pdfPages, async (res, ind) => {
            this.pdfViewerComponent.renderallPages(res, (ind));
          });
        });
      },2000); */
    //this.progressBarValue = 3;
    this.pdfViewerComponent.loadAllPages().subscribe(result => {
      this.pdfViewerComponent.pdfPages = result;

      setTimeout(() => {
        for (let i = 0; i < this.pdfViewerComponent.pdfPages.length; i++) {
          promises.push(this.pdfViewerComponent.renderallPages(this.pdfViewerComponent.pdfPages[i], i));
        } 

        Promise.all(promises).then(() => { 
          //this.progressBarValue = 7;
          console.log(promises);
          //this.pdfViewerComponent.renderallPages(this.pdfViewerComponent.pdfPages, 0)

          this.editedPdfPages = [];
          this.actionData = actionData;

          var mainDiv = document.getElementsByClassName('page2') as HTMLCollection;
          var doc = new jsPDF("p", "pt", "a4", false);

          for (var i = 0; i < this.pdfViewerComponent.editings.length; i++) {
            if (this.pdfViewerComponent.editings[i].editings.textEditings.length > 0 ||
              this.pdfViewerComponent.editings[i].editings.rectangleouts.length > 0 ||
              this.pdfViewerComponent.editings[i].editings.images.length > 0 ||
              this.pdfViewerComponent.editings[i].editings.signatures.length > 0 ||
              this.pdfViewerComponent.editings[i].editings.arrows.length > 0 ||
              this.pdfViewerComponent.editings[i].editings.stamps.length > 0) {
              this.editedPdfPages.push({ 'editedpagenumber': i });
              this.totalEditedPage++;
            }
            else {
              this.editedPdfPages.push({ 'editedpagenumber': null });
            }
          }
          
          
            
            this.AddImageToPDF(mainDiv, doc, 0);
          
        });
      }, 4000);
    });
  }

  public checkInandClosePdf(action){
    if (this.spService.onedrive == "true") {
      this.graphService.checkOutCheckIn('checkin', this.driveid,this.docid).then((res) => {
        console.log('checkInDone');
        window.opener = window;
        window.close();
      }).catch(err => {
        console.log('checkOutError', err);
      });
    }else{
      this.graphService.checkOutCheckIn('checkin', '','').then((res) => {
        console.log('checkInDone');
        window.opener = window;
        window.close();
      }).catch(err => {
        console.log('checkOutError', err);
      });
    }
    
  }

  public async AddImageToPDF(mainDiv: HTMLCollection, doc: jsPDF, cnt: number): Promise<void> { 
    this.msgs = [];
    if (this.pdfViewerComponent.totalpagecount != mainDiv.length) {
      /*if (this.blockUI) {
        this.blockUI.stop();
      }*/
      this.spService.isSaveDownloadClick = false;
      this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: "Please try again, unknown error occurred." });
    }
    else {
      if (cnt != mainDiv.length) {
        if (this.editedPdfPages[cnt].editedpagenumber == cnt) {
          this.blockUI.update('Please make some coffee while we re assemble your PDF file. This will be done shortly... Page: '+cnt+'/'+this.pdfViewerComponent.totalpagecount+'.');
          // if (cnt != this.currentPage)
          //   mainDiv[cnt].classList.remove("pagehide");
          let DataSelected = mainDiv[cnt].getElementsByClassName('selected');
          if (DataSelected.length > 0) {
            DataSelected[0].classList.remove("selected");
          }
          html2canvas((mainDiv[cnt] as HTMLElement), { scale: 1, allowTaint: true, useCORS: true }).then((canvas) => {
            
            var img = new Image();

            if (canvas.width < 799) {

              this.spService.isSaveDownloadClick = false;
              this._ngZone.run(() => {
                console.log('Outside Done!');
              });
              this.blockUI.update('Unknown error occurred, Please try again.');
              //this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: "Unknown error occurred, Please try again." });

              setTimeout(() => {
                window.location.reload();
              }, 3000);

              return;
            }
            
            var Dataimg = canvas.toDataURL('image/jpeg', 1.0);

            img.crossOrigin = 'Anonymous';
            img.src = Dataimg;

            doc.addImage(img, 'JPEG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), null, 'FAST');

            cnt++;
            this.editingPage++;
            if (cnt != mainDiv.length)
              doc.addPage();

            
            let Data = parseInt(((cnt / this.pdfViewerComponent.totalpagecount) * 100).toFixed(0));
            
              this.commonService.emitProgressValue(Data);
                       
            // console.log(((this.editingPage / this.totalEditedPage) * 100).toFixed(0));
            
            //this.progressBarValue = this.progressBarValue + parseInt((90/cnt).toPrecision(1));
              this.AddImageToPDF(mainDiv, doc, cnt);
          }).catch(e => { console.log(e); });
        } else {
          this.blockUI.update('Please make some coffee while we re assemble your PDF file. This will be done shortly... Page: '+cnt+'/'+this.pdfViewerComponent.totalpagecount+'.');
          let allCanvas = null;
          let currentCanvas = null;

          allCanvas = mainDiv[cnt].getElementsByClassName('pdfCanvas2');
          currentCanvas = allCanvas.item(0) as HTMLCanvasElement;

          if (currentCanvas.width < 799) {

            this.spService.isSaveDownloadClick = false;
            this._ngZone.run(() => {
              console.log('Outside Done!');
            });
            this.blockUI.update('Unknown error occurred, Please try again.');
            //this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: "Unknown error occurred, Please try again." });

            setTimeout(() => {
              window.location.reload();
            }, 3000);

            return;
          }

          var img = currentCanvas.toDataURL('image/jpeg', 1.0);

          var cusomtImg = new Image();
          cusomtImg.src = img;

          doc.addImage(cusomtImg, 'JPEG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), null, 'FAST');

          // if (cnt != this.currentPage)
          //   mainDiv[cnt].classList.add("pagehide");
          cnt++;

          if (cnt != mainDiv.length)
            doc.addPage();
          
          //this.blockUI.start('Please make some coffee while we re assemble your PDF file. This will be done shortly... Page: '+cnt+'/'+this.pdfViewerComponent.totalpagecount+'.');
          let Data = parseInt(((cnt / this.pdfViewerComponent.totalpagecount) * 100).toFixed(0));
            
            this.commonService.emitProgressValue(Data);
          // var Data = ((cnt / mainDiv.length) * 100) + '%';
          // console.log(((cnt / mainDiv.length) * 100));
          
          this.AddImageToPDF(mainDiv, doc, cnt);
        }
      }
      else {
        //this.progressBarValue = 100;
        this.msgs = [];
        //debugger;
        if (this.actionData == 'save') {
          //this.progressBarValue = 0;
          var modal = document.getElementById('domMessage'); 
          var blob = doc.output('blob');
          console.log(blob);

          if (blob.size > 4000000) {
            this.graphService.getUploadSessio().then((res) => {
              console.log(res);
              var uploadUrl = res.uploadUrl;
              //this.blockUI.update('Saving PDF File...');
              //this.blockUI.start( ' Just a moment...');
              //this.blockUI.update('This will be done shortly.');
              this.graphService.uploadChunks(blob, uploadUrl).then((result) => {
                console.log(result);
                this.spService.isSaveDownloadClick = false;

                this.updateUsageAfterSaveDownlad();
                this.createContactInHubspotAfterSave();
                this.msgs.push({ severity: Constants.successSeverity, summary: Constants.successSummary, detail: Constants.savePDF });

                if (this.spService.onedrive == "true") {
                  //this.commonService.emitProgressValue(95);
                  this.graphService.checkOutCheckIn('checkin', this.driveid, this.docid).then((res) => {
                    console.log('checkInDone');
                  }).catch(err => {
                    console.log('checkOutError', err);
                  });
                  this.spService.clearEditingData().subscribe(() => {
                    this.removeLocalstorageItem();
                    if (this.blockUI) {
                      this.commonService.emitProgressValue(100);
                      this.blockUI.stop();
                    }
                    this.route.navigate(['/onedrive']);
                  }, err => {
                    if (this.blockUI) {
                      this.blockUI.stop();
                      modal.style.display = "none";
                    }
                    this.showError(err);
                  });
                }
                else {
                  //this.commonService.emitProgressValue(95);
                  var Data = this.spService.sharepointDriveInfo.split('/');
                  console.log(Data);
                  this.graphService.checkOutCheckIn('checkout', Data[1], Data[3]).then((res) => {

                    this.graphService.checkOutCheckIn('checkin', Data[1], Data[3]).then((res) => {
                      this.spService.clearEditingData().subscribe(() => {
                        this.removeLocalstorageItem();
                        if (this.blockUI) {
                          this.commonService.emitProgressValue(100);
                          this.blockUI.stop();
                        }
                        this.route.navigate(['/onedrive']);
                      }, err => {
                        if (this.blockUI) {
                          this.blockUI.stop();
                          modal.style.display = "none";
                        }
                        this.showError(err);
                      });
                    });
                  });
              }
              }).catch((err) => {
                if (this.blockUI) {
                  this.blockUI.stop();
                  modal.style.display = "none";
                }
                console.log(err);
                this.showError(err);
              })
            })
          }
          else {
            //this.blockUI.update('Saving PDF File...');

            /* this.blockUI.start('Please make some coffee while we re assemble your PDF file. This will be done shortly');
            var modal = document.getElementById('domMessage');            
            modal.style.display = "block";
            console.log("dommessage", modal); */
            //this.blockUI.update('This will be done shortly.');
            this.graphService.uploadFile(blob).then((res) => {
              console.log("SAVE RES", res);
              this.spService.isSaveDownloadClick = false;

              this.updateUsageAfterSaveDownlad();
              this.createContactInHubspotAfterSave();
              this.msgs.push({ severity: Constants.successSeverity, summary: Constants.successSummary, detail: Constants.savePDF });

              if (this.spService.onedrive == "true") {
                //this.commonService.emitProgressValue(95);
                this.graphService.checkOutCheckIn('checkin', res.parentReference.driveId, res.id).then((res) => {
                  console.log('checkInDone');
                }).catch(err => {
                  console.log('checkOutError', err);
                });
                this.spService.clearEditingData().subscribe(() => {
                  this.removeLocalstorageItem();
                  if (this.blockUI) {
                    this.commonService.emitProgressValue(100);
                    this.blockUI.stop();
                  }
                  this.route.navigate(['/onedrive']);
                }, err => {
                  if (this.blockUI) {
                    this.blockUI.stop();
                    modal.style.display = "none";
                  }
                  this.showError(err);
                });
              }
              else {
                //this.commonService.emitProgressValue(95);
                var driveID=res.parentReference.driveId;
                var itemID=res.id;
                this.graphService.checkOutCheckIn('checkout', driveID, itemID).then((res) => {
                  this.graphService.checkOutCheckIn('checkin', driveID, itemID).then((res) => {
                    this.spService.clearEditingData().subscribe(() => {
                      this.removeLocalstorageItem();
                      this.sharedisplay = false;
                      if (this.blockUI) {
                        this.commonService.emitProgressValue(100);
                        this.blockUI.stop();
                      }
                      this.route.navigate(['/onedrive']);
                    }, err => {
                      if (this.blockUI) {
                        this.blockUI.stop();
                      }
                      this.showError(err);
                    });

                }).catch((e) => {
                  console.log('checkinerror', e)
                  //this.route.navigate(['/onedrive']);
                });
                });
              }
            }).catch((err) => {
              if (this.blockUI) {
                this.blockUI.stop();
                modal.style.display = "none";
              }
              console.log(err);
              this.showError(err);
            });
          }
        }
        else {
          this.spService.isSaveDownloadClick = false;
          this._ngZone.run(() => {
            console.log('Outside Done!');
          });
          if (this.spService.onedrive == "true") {
            var name = this.spService.currentDocumentOnedrive.webUrl.split('/');
          }
          else {
            var name = this.spService.currentDocument.pdfsource.split('/');
          }          
          this.blockUI.update('Downloading PDF File...');
          var filename = name[name.length - 1];
          doc.save(decodeURIComponent(filename));
          this.updateUsageAfterSaveDownlad();

          this.msgs.push({ severity: Constants.successSeverity, summary: Constants.successSummary, detail: Constants.downloadPDF });

          setTimeout(() => {
            if (this.blockUI) {
              this.blockUI.stop();
              modal.style.display = "none";
            }
            this._ngZone.run(() => {
              console.log('Outside Done!');
            });
            window.location.reload();
          }, 2000);
        }
      }
    }
  }

  public sendLink() {

    this.spService.isSaveDownloadClick = true;
    this._ngZone.run(() => {
      console.log('Outside Done!');
    });
   // this.commonService.emitProgressValue(0);
    this.sharedisplay = false;
    this.blockuipdf = false;

    if(this.user==null || this.user.Name==undefined){
      this.msgs = [];
      this.msgs.push({ detail: Constants.userNotExists });
      this.spService.isSaveDownloadClick = false;
      this._ngZone.run(() => {
        console.log('Outside Done!');
      });
     // window.location.reload();
    }
    else if (this.spService.adminuserdetails.RemainingUsage == 0) {
      this.msgs = [];
      this.msgs.push({detail: Constants.monthlyLimitReached });
      this.spService.isSaveDownloadClick = false;
      this._ngZone.run(() => {
        console.log('Outside Done!');
      });
      //window.location.reload();
    }
    else {
      this.confirmationService.confirm({
        message: 'The document will be saved first and then shared with user. Your remaining Save/Download/Share Link counts after this operation will be : ' + (+this.spService.adminuserdetails.RemainingUsage - 1) + ', Confirm?',
        accept: () => {

          //this.isLoading = true;

          //this.blockUI.start('Please Wait...');
          this.blockUI.start('Please make some coffee while we re assemble your PDF file. This will be done shortly..');
    var modal = document.getElementById('domMessage');            
    modal.style.display = "block";
    console.log("dommessage", modal);
          // setTimeout(() => {
          //   this.pdfViewerComponent.loadAllPages().subscribe(result => {
          //     this.pdfViewerComponent.pdfPages = result;
          //     asyncForEach(this.pdfViewerComponent.pdfPages, async (res, ind) => {
          //       this.pdfViewerComponent.renderallPages(res, (ind));
          //     });
          //   });
          // }, 1000);


          // setTimeout(() => {

          //   var mainDiv = document.getElementsByClassName('page2') as HTMLCollection;
          //   var doc = new jsPDF("p", "pt", "a4", false);

          //   for (var i = 0; i < this.pdfViewerComponent.editings.length; i++) {
          //     if (this.pdfViewerComponent.editings[i].editings.textEditings.length > 0 ||
          //       this.pdfViewerComponent.editings[i].editings.rectangleouts.length > 0 ||
          //       this.pdfViewerComponent.editings[i].editings.images.length > 0 ||
          //       this.pdfViewerComponent.editings[i].editings.signatures.length > 0 ||
          //       this.pdfViewerComponent.editings[i].editings.arrows.length > 0 ||
          //       this.pdfViewerComponent.editings[i].editings.stamps.length > 0) {
          //       this.editedPdfPages.push({ 'editedpagenumber': i });
          //     }
          //     else {
          //       this.editedPdfPages.push({ 'editedpagenumber': null });
          //     }
          //   }

          //   this.actionData = 'save';
          //   this.AddDataToPDF(mainDiv, doc, 0);
          // }, 10000);

          var promises = [];
          this.pdfViewerComponent.loadAllPages().subscribe(result => {
            console.log("result............", result);
            this.pdfViewerComponent.pdfPages = result;
            setTimeout(() => {
              for (let i = 0; i < this.pdfViewerComponent.pdfPages.length; i++) {
                console.log(i);
                promises.push(this.pdfViewerComponent.renderallPages(this.pdfViewerComponent.pdfPages[i], i));
              }

              Promise.all(promises).then(() => {
                console.log(promises);
                //this.pdfViewerComponent.renderallPages(this.pdfViewerComponent.pdfPages, 0)

                this.editedPdfPages = [];


                var mainDiv = document.getElementsByClassName('page2') as HTMLCollection;
                var doc = new jsPDF("p", "pt", "a4", false);

                for (var i = 0; i < this.pdfViewerComponent.editings.length; i++) {
                  if (this.pdfViewerComponent.editings[i].editings.textEditings.length > 0 ||
                    this.pdfViewerComponent.editings[i].editings.rectangleouts.length > 0 ||
                    this.pdfViewerComponent.editings[i].editings.images.length > 0 ||
                    this.pdfViewerComponent.editings[i].editings.signatures.length > 0 ||
                    this.pdfViewerComponent.editings[i].editings.arrows.length > 0 ||
                    this.pdfViewerComponent.editings[i].editings.stamps.length > 0) {
                    this.editedPdfPages.push({ 'editedpagenumber': i });
                    this.totalEditedPage++;
                  }
                  else {
                    this.editedPdfPages.push({ 'editedpagenumber': null });
                  }
                }
                //setTimeout(() => {
                this.actionData = 'save';
                this.AddDataToPDF(mainDiv, doc, 0);
                //}, 1000);
              });
            }, 5000);
          });
        }, reject: () => {
          this.spService.isSaveDownloadClick = false;
          this._ngZone.run(() => {
            console.log('Outside Done!');
          });
          window.location.reload();
        }
      });
    }
  }


  public AddDataToPDF(mainDiv: HTMLCollection, doc: jsPDF, cnt: number): void {
    var modal = document.getElementById('domMessage');  
    if (this.pdfViewerComponent.totalpagecount != mainDiv.length) {
      if (this.blockUI) {
        this.blockUI.stop();
        modal.style.display = "none";
      }
      this.spService.isSaveDownloadClick = false;
      this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: "Please try again, unknown error occurred." });
    }
    else {
      this.msgs = [];

      if (cnt != mainDiv.length) {
        if (this.editedPdfPages[cnt].editedpagenumber == cnt) {
          // if (cnt != this.currentPage)
          //   mainDiv[cnt].classList.remove("pagehide");

          let DataSelected = mainDiv[cnt].getElementsByClassName('selected');
          if (DataSelected.length > 0) {
            DataSelected[0].classList.remove("selected");

          }
          html2canvas((mainDiv[cnt] as HTMLElement), { scale: 1, allowTaint: true, useCORS: true }).then((canvas) => {

            var img = new Image();

            if (canvas.width < 799) {

              // if (this.blockUI) {
              //   this.blockUI.stop();
              // }
              this.spService.isSaveDownloadClick = false;
              this._ngZone.run(() => {
                console.log('Outside Done!');
              });
              this.blockUI.update('Unknown error occurred, Please try again.');
              //this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: "Unknown error occurred, Please try again." });

              setTimeout(() => {
                window.location.reload();
              }, 3000);

              return;
            }
            
            var Dataimg = canvas.toDataURL("image/jpeg", 1.0);
            img.crossOrigin = 'Anonymous';
            img.src = Dataimg;

            doc.addImage(img, 'JPEG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), null, 'FAST');

            //doc.addImage(img, 'jpeg', 0, 0, 211, 298);
            // if (cnt != this.currentPage)
            //   mainDiv[cnt].classList.add("pagehide");
            cnt++;
            this.editingPage++;
            if (cnt != mainDiv.length)
              doc.addPage();

            //this.blockUI.update('Creating PDF File...');
           // let Data = parseInt(((this.editingPage / this.totalEditedPage) * 100).toFixed(0));
            //this.commonService.emitProgressValue(Data);            
            this.AddDataToPDF(mainDiv, doc, cnt);
          }).catch(e => { console.log(e); });
        } else {

          let allCanvas = null;
          let currentCanvas = null;

          // if (cnt != this.currentPage)
          //   mainDiv[cnt].classList.remove("pagehide");

          allCanvas = mainDiv[cnt].getElementsByClassName('pdfCanvas2');
          currentCanvas = allCanvas.item(0) as HTMLCanvasElement;

          if (currentCanvas.width < 799) {

            // if (this.blockUI) {
            //   this.blockUI.stop();
            // }
            this.spService.isSaveDownloadClick = false;
            this._ngZone.run(() => {
              console.log('Outside Done!');
            });
            this.blockUI.update('Unknown error occurred, Please try again.');
            //this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: "Unknown error occurred, Please try again." });

            setTimeout(() => {
              window.location.reload();
            }, 3000);

            return;
          }

          var img = currentCanvas.toDataURL("image/jpeg", 1.0);
          var cusomtImg = new Image();
          cusomtImg.src = img;

          doc.addImage(cusomtImg, 'JPEG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), null, 'FAST');

          //doc.addImage(cusomtImg, 'jpeg', 0, 0, 211, 298);

          // if (cnt != this.currentPage)
          //   mainDiv[cnt].classList.add("pagehide");
          cnt++;

          if (cnt != mainDiv.length)
            doc.addPage();
          this.AddDataToPDF(mainDiv, doc, cnt);
        }
      }
      else {
        this.msgs = [];

        if (this.actionData == 'save') {
          var blob = doc.output('blob');

          if (blob.size > 4000000) {
            this.graphService.getUploadSessio().then((res) => {
              var uploadUrl = res.uploadUrl;
              //this.blockUI.update('Saving PDF File...');
              //this.blockUI.start('<h1> Just a moment...</h1>' );
              //this.blockUI.update('This will be done shortly.');
              this.graphService.uploadChunks(blob, uploadUrl).then((result) => {
                console.log(result);

                var content = "savedDocumentUrl :- " + result['webUrl'] + " <br />";

                console.log(content);
                console.log(this.user.Email);

                var sendData = {
                  "message": {
                    "subject": "Share Edited Document URL",
                    "body": {
                      "contentType": "HTML",
                      "content": content,
                    },
                    "toRecipients": [
                      {
                        "emailAddress": {
                          "address": this.user.Email
                        }
                      }
                    ]
                  },
                  "saveToSentItems": "false"
                }
                this.blockUI.update('Sending Email...');
                this.graphService.sendEmail(sendData).then(result => {

                  this.spService.isSaveDownloadClick = false;
                  this.sharedisplay = false;
                  this._ngZone.run(() => {
                    console.log('Outside Done!');
                  });

                  this.updateUsageAfterSaveDownlad();
                  this.createContactInHubspotAfterSave();
                  this.msgs.push({ severity: Constants.successSeverity, summary: Constants.successSummary, detail: Constants.send });

                  if (this.spService.onedrive == "true") {
                    this.graphService.checkOutCheckIn('checkin', this.driveid, this.docid).then((res) => {
                      console.log('checkInDone');
                    }).catch(err => {
                      console.log('checkOutError', err);
                    });
                    this.spService.clearEditingData().subscribe(() => {
                      this.removeLocalstorageItem();
                      if (this.blockUI) {
                        this.blockUI.stop();
                      }
                      this.route.navigate(['/onedrive']);
                    }, err => {
                      if (this.blockUI) {
                        this.blockUI.stop();
                        modal.style.display = "none";
                      }
                      this.showError(err);
                    });
                  }
                  else {
                    var Data = this.spService.sharepointDriveInfo.split('/');
                    console.log(Data);
                    this.graphService.checkOutCheckIn('checkin', Data[1], Data[3]).then((res) => {
                      this.spService.clearEditingData().subscribe(() => {
                        this.removeLocalstorageItem();
                        if (this.blockUI) {
                          this.blockUI.stop();
                        }
                        this.route.navigate(['/onedrive']);
                      }, err => {
                        if (this.blockUI) {
                          this.blockUI.stop();
                          modal.style.display = "none";
                        }
                        this.showError(err);
                      });
                    });
                  }
                }).catch(err => {
                  this.showError(err);
                  console.log(err)
                });
              }).catch((err) => {
                if (this.blockUI) {
                  this.blockUI.stop();
                  modal.style.display = "none";
                }
                console.log(err);
                this.showError(err);
              })
            })
          }
          else {
            //this.blockUI.update('Saving PDF File...');
            //this.blockUI.start('<h1> Just a moment...</h1>' );
            //this.blockUI.update('This will be done shortly.');
            this.graphService.uploadFile(blob).then((res) => {
              console.log("SAVE RES", res);
              var content = "savedDocumentUrl :- " + res['webUrl'] + " <br />";

              console.log(content);
              console.log(this.user.Email);

              var sendData = {
                "message": {
                  "subject": "Share Edited Document URL",
                  "body": {
                    "contentType": "HTML",
                    "content": content,
                  },
                  "toRecipients": [
                    {
                      "emailAddress": {
                        "address": this.user.Email
                      }
                    }
                  ]
                },
                "saveToSentItems": "false"
              }
              this.blockUI.update('Sending Email...');
              this.graphService.sendEmail(sendData).then(result => {

                this.spService.isSaveDownloadClick = false;
                this.sharedisplay = false;
                this._ngZone.run(() => {
                  console.log('Outside Done!');
                });

                this.updateUsageAfterSaveDownlad();
                this.createContactInHubspotAfterSave();
                this.msgs.push({ severity: Constants.successSeverity, summary: Constants.successSummary, detail: Constants.send });

                if (this.spService.onedrive == "true") {
                  this.graphService.checkOutCheckIn('checkin', res.parentReference.driveId, res.id).then((res) => {
                    console.log('checkInDone');
                  }).catch(err => {
                    console.log('checkOutError', err);
                  });
                  this.spService.clearEditingData().subscribe(() => {
                    this.removeLocalstorageItem();
                    this.sharedisplay = false;
                    if (this.blockUI) {
                      this.blockUI.stop();
                    }
                    this.route.navigate(['/onedrive']);
                  }, err => {
                    if (this.blockUI) {
                      this.blockUI.stop();
                      modal.style.display = "none";
                    }
                    this.showError(err);
                  });
                }
                else {
                  var driveID=res.parentReference.driveId;
                  var itemID=res.id;
                  this.graphService.checkOutCheckIn('checkout', driveID, itemID).then((res) => {
                    this.graphService.checkOutCheckIn('checkin', driveID, itemID).then((res) => {
                      this.spService.clearEditingData().subscribe(() => {
                        this.removeLocalstorageItem();
                        this.sharedisplay = false;
                        if (this.blockUI) {
                          this.blockUI.stop();
                        }
                        this.route.navigate(['/onedrive']);
                      }, err => {
                        if (this.blockUI) {
                          this.blockUI.stop();
                          modal.style.display = "none";
                        }
                        this.showError(err);
                      });

                  }).catch((e) => {
                    console.log('checkinerror', e)
                    //this.route.navigate(['/onedrive']);
                  });
                  });
                }
              }).catch(err => {
                if (this.blockUI) {
                  this.blockUI.stop();
                  modal.style.display = "none";
                }
                this.showError(err);
                console.log(err)
              })
            }).catch((err) => {
              if (this.blockUI) {
                this.blockUI.stop();
                modal.style.display = "none";
              }
              this.showError(err);
              console.log(err)
            });
          }
        }
      }
    }
  }

  public updateUsageAfterSaveDownlad() {
    this.spService.updateUsage(+this.spService.adminuserdetails.RemainingUsage - 1).subscribe((res) => {

      if (typeof(res) != "number") {
        this.msgs = [];
        this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: res.toString() });
      }
      else {
        var Data = this.spService.adminuserdetails;
        this.msgs = [];
        this.msgs.push({ severity: Constants.infoSeverity, summary: Constants.infoSummary, detail: Constants.remainigData + (res.toString()) });
        Data.RemainingUsage = res.toString();
        this.spService.adminuserdetails = Data;
      }
    }, err => {
      this.showError(err);
    });
  }

  public createContactInHubspotAfterSave() {
    this.spService.createContactInHubspotAfterSave().subscribe(res => {
    }, err => {
      this.showError(err);
    });
  }

  public changePageNumber(data, e: MouseEvent) {

    this.isLoading = true;
    this.ischnagenumber = true;
    var input = e.target as HTMLElement;
    setTimeout(() => {

      if (this.pagenumberModel > 0 && this.pagenumberModel <= this.totalPages) {
        if ((parseInt(data) - 1) < this.totalPages && (parseInt(data) - 1) >= 0) {
          this.currentPage = data - 1;
          this.gotopage = data - 1;
        }
        else {
          this.currentPage = 0;
          this.gotopage = 0;
        }
      }
      else if (this.pagenumberModel > this.totalPages) {
        this.pagenumberModel = this.totalPages;
        let lastPageButton: HTMLCollection = document.getElementsByClassName('ui-paginator-last');
        let btn = lastPageButton[0] as HTMLElement;
        btn.click();
      }
      else {
        this.pagenumberModel = 1;
        let lastPageButton: HTMLCollection = document.getElementsByClassName('ui-paginator-first');
        let btn = lastPageButton[0] as HTMLElement;
        btn.click();
      }

      this.getCurrentPageUndoEditings();
      this.isLoading = false;
      this.ischnagenumber = false;
      this.elementRef.nativeElement.focus();
    }, 500);
  }

  public myUploader(event, form) {
    this.elementType = "Image";
    this.uploadedImage = event;
    form.clear();
  }

  public checkAction(data) {
    if (data) {
      this.elementType = "Annotations";
      this.selectedAnnotations = data;
    }
    else {
      this.elementType = 'None';
      this.selectedAnnotations = '';
    }
  }

  public checkArrow(data) {
    if (data) {
      this.elementType = "Arrow";
      this.selectedArrows = data;
    }
    else {
      this.elementType = 'None';
      this.selectedArrows = '';
    }
  }

  public checkCallout(data) {
    if (data) {
      this.elementType = "Callout";
      this.selectedCallout = data;
    }
    else {
      this.elementType = 'None';
      this.selectedCallout = '';
    }

  }

  public savePdfData(Data) {
    this.pdfService.updateDocumentData(this.pdfViewerComponent.editings).subscribe(res => {
      this.msgs = [];
      this.msgs.push({ severity: Constants.successSeverity, summary: Constants.successSummary, detail: Constants.saveEditings });
    }, err => {
      this.showError(err);
    });
  }

  public filterUser(data) {
    console.log(data);
    this.graphService.getAllUsers(data.query).then((res) => {
      let arr = [];
      if (res.value.length > 0) {
        for (var i = 0; i < res.value.length; i++) {
          if (res.value[i].userPrincipalName) {
            let obj: any = { Id: res.value[i].ID, Name: res.value[i].displayName, Email: res.value[i].userPrincipalName };
            arr.push(obj);
          }
        }
        this.userNames = arr;
        console.log(this.userNames);
      }

    }).catch((err) => {
      this.showError(err);
    });
  }

  public onStampsSave() {
    var Data = {
      'SName': this.stampText,
      'TextColor': this.stampTextColor,
      'TenantId': this.spService.tenantID,
      'CompanyName': this.stampCompanyText
    };

    this.spService.insertStamps(Data).subscribe((res: any) => {
      if (res.SName) {
        this.elementType = "Stamps";
        this.selectedStamp = res;
        this.getStamps();
      }
    }, err => {
      this.showError(err);
    });
    this.onStampsCancel();
  }

  public onStampsCancel() {
    this.stampTextColor = '#000000';
    this.stampText = '';
    this.stampCompanyText = '';
    this.selectedStamps = [];
    this.sdisplay = false;
    this.mdisplay = false;
    this.stampTabIndex = 0;
  }

  public onStampsDelete() {
    this.spService.DeleteStapms(this.selectedStamps).subscribe((res) => {
      this.getStamps();
      this.sdisplay = false;
      this.mdisplay = false;
    }, err => {
      this.showError(err);
    });
  }

  public checkStamps(data) {
    if (data) {
      if (this.stamp_model.code == 'CreateNew') {
        this.sdisplay = true;
        this.mdisplay = false;
      }
      else if (this.stamp_model.code == 'ManageStamps') {
        this.mdisplay = true;
        this.sdisplay = false;
      }
      else {
        this.sdisplay = false;
        this.mdisplay = false;
        var Data: any = [];
        Data.SName = this.stamp_model.name;
        Data.CompanyName = this.stamp_model.code.split('-')[1];
        Data.TextColor = this.stamp_model.code.split('-')[0];
        this.elementType = "Stamps";
        this.selectedStamp = Data;
      }
    }
    else {
      this.elementType = 'None';
      this.selectedStamp = '';
    }
  }

  public canvasClicked(event?: string) {
    this.elementType = '';
    this.getCurrentPageUndoEditings();
  }

  public onKey(e: KeyboardEvent) {
    if (e.keyCode == 13) {
      e.preventDefault();
      var input = e.target as HTMLElement;
      input.blur();
      return false;
    }
  }

  public handleChange(e) {
    this.stampTabIndex = e.index;
  }

  public handleClick(e: Event) {
    let clickedLink: HTMLElement = (e.srcElement || e.target) as HTMLElement;
    this.elementType = clickedLink.innerText.trim();
    this.selectedType(e);
  }

  public showannotations(Data) {
    this.selectannotation = Data;
  }

  public selectedType(e: Event) {
    let menuLinks: HTMLCollection = document.getElementsByClassName('ui-button-secondary');
    for (var i = 0; i < menuLinks.length; i++) {
      menuLinks[i].classList.remove('active');
    }

    let clickedLink: HTMLElement = e.target as HTMLElement;
    clickedLink.classList.add('active');
  }

  public backtopdfviewer() {
    this.removeLocalstorageItem();
    this.route.navigate(['/onedrive']);
  }

  public sendShareableLink() {
    this.sharedisplay = true;
    this.blockuipdf = true;
    this.user = '';
  }

  public handleStamps() {
    console.log("this.sdisplay", this.sdisplay);
    this.sdisplay = true;
    console.log("this.sdisplay1", this.sdisplay);
  }

  public removeLocalstorageItem() {
    localStorage.removeItem("DocDataOnedrive");
    localStorage.removeItem("sharepointDriveInfo");
    localStorage.removeItem("DocData");
    localStorage.removeItem("doclibraryname");
    localStorage.removeItem("documentLibraryName");
    localStorage.removeItem("documentFolder");
    localStorage.removeItem("tenantID");
    localStorage.removeItem("checkDocumentStatus");
    localStorage.removeItem("isTenantRegister");
    localStorage.removeItem("adminuserdetails");
    localStorage.removeItem("onedrive");
  }

  public closeModal() {
    this.sharedisplay = false;
    this.blockuipdf = false;
    this.sdisplay = false;
    this.signatureModel = false;

    console.log(this.sharedisplay);
  }

  public getCurrentPageUndoEditings() {
    if (this.pdfViewerComponent.undoEditings && this.pdfViewerComponent.undoEditings.length > 0) {
      this.currentPageUndoEditings = this.pdfViewerComponent.undoEditings.filter(ele => ele.CurrentPage == this.currentPage);
      if (this.currentPageUndoEditings && this.currentPageUndoEditings.length > 0) {
        this.isUndoDisabled = false;
      }
      else {
        this.isUndoDisabled = true;
      }
    }

    if (this.pdfViewerComponent.deletedEditings && this.pdfViewerComponent.deletedEditings.length > 0) {
      this.currentPageDeletedEditings = this.pdfViewerComponent.deletedEditings.filter(ele => ele.CurrentPage == this.currentPage);
      if (this.currentPageDeletedEditings && this.currentPageDeletedEditings.length > 0) {
        this.isUndoDisabled = false;
      }
      else {
        this.isUndoDisabled = true;
      }
    }

    if (this.pdfViewerComponent.undoOperations && this.pdfViewerComponent.undoOperations.length > 0) {
      this.currentPageUndoOperations = this.pdfViewerComponent.undoOperations.filter(ele => ele.CurrentPage == this.currentPage);
      if (this.currentPageUndoOperations && this.currentPageUndoOperations.length > 0) {
        this.isUndoDisabled = false;
      }
      else {
        this.isUndoDisabled = true;
      }
    }
  }

  public showError(err) {
    this.msgs = [];
    this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: err.toString() });
  }

  public getFolderName(data) {
    var name;
    var rname;
    var doclibrary = data.split('/');
    if (decodeURIComponent(doclibrary[doclibrary.length - 2]) == "Shared Documents") {
      rname = "Documents";
    }
    else {
      rname = decodeURIComponent(doclibrary[doclibrary.length - 2]);
    }

    if (this.spService.doclibraryName == rname) {
      name = "Root";
    }
    else {
      var newdata = data.split('/'+encodeURIComponent(this.spService.doclibraryName)+'/')
      var endIndex = newdata[1].lastIndexOf("/");
      if (endIndex != -1)  
      {
        name = newdata[1].substring(0, endIndex); // not forgot to put check if(endIndex != -1)
      }else{
        name = doclibrary[doclibrary.length - 2];
      }
      
    }
    return decodeURIComponent(name);
  }

  public selectSignature() {
    //this.blockUI.start('Please Wait.');
    this.isLoading = true;
    this.graphService.checkFolderIsExist().then(res => {      
      if (res.value.length == 0) {
        this.graphService.createFolder().then(res2 => {          
          this.graphService.createSubFolder(res2.id).then((res3) => {            
            this.signatureUrl = res3.webUrl;
            this.signatureModel = true;

            // if (this.blockUI) {
            //   this.blockUI.stop();
            // }
            this.isLoading = false;

            this._ngZone.run(() => {
              console.log('Outside Done!');
            });
            // alert("Please save a jpeg graphic file of your signature to this location in your OneDrive:" + res3.webUrl);
          }).catch((err3) => {
            console.error(err3);
            // if (this.blockUI) {
            //   this.blockUI.stop();
            // }
            this.isLoading = false;
          })
        }).catch((err2) => {
          console.error(err2)
          // if (this.blockUI) {
            //   this.blockUI.stop();
            // }
            this.isLoading = false;
        });
      }
      else {        
        this.graphService.getSignatureFile(res.value[0].id).then((res4: any) => {
          if (res4.value[0].name == "Signature") {            
            this.signatureUrl = res4.value[0].webUrl;
            this.signatureModel = true;
          }
          else {            
            this.elementType = "Signature";
            this.selectedSignature = res4.value[0]["@microsoft.graph.downloadUrl"];
          }
          // if (this.blockUI) {
            //   this.blockUI.stop();
            // }
            this.isLoading = false;
        }).catch((err) => {
          console.log(err);
          // if (this.blockUI) {
            //   this.blockUI.stop();
            // }
            this.isLoading = false;
        })
      }
    }).catch((err) => {
      console.error(err);
      this.alertsService.add('Error: notAllowed', JSON.stringify(err["message"], null, 2));
      // this.msgs = [];
      // this.msgs.push({ severity: Constants.errorSeverity, summary: Constants.errorSummary, detail: JSON.stringify(err["message"]) });      
      // if (this.blockUI) {
            //   this.blockUI.stop();
            // }
            this.isLoading = false;
    })
  }

  checkBrowser() {
    var x = "";
    if (navigator.userAgent.indexOf("Edge") > -1 && navigator.appVersion.indexOf('Edge') > -1) {
      x = 'Edge';
    }
    else if (navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1) {
      x = 'Opera';
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1) {
      x = 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") != -1) {
      x = 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      x = 'Firefox';
    }
    else if ((navigator.userAgent.indexOf("Trident") != -1)) //IF IE > 10
    {
      x = 'IE';
    }
    else {
      x = 'unknown';
    }

    return x;
  }
}
