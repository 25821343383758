import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { Observable } from '../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  public Data: string = "";

  constructor(private _authService: AuthService, private _router: Router) {
    this.Data = this.checkBrowser();
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.Data == 'IE' || this.Data == 'Edge') {
      if (!this._authService.authenticated) {
        this._router.navigate(['/home']);          
      }      
      else {
        return true;
      }
    }
    else {       
      return this._authService.safeLogin().then((res) => {
        if (res) {
          return true;
        }
        else {
          this._router.navigate(['/home']);
          // you can save redirect url so after authing we can move them back to the page they requested
          return false;
        }
      });            
    }
  }

  checkBrowser() {
    var x = "";
    if (navigator.userAgent.indexOf("Edge") > -1 && navigator.appVersion.indexOf('Edge') > -1) {
      x = 'Edge';
    }
    else if (navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1) {
      x = 'Opera';
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1) {
      x = 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") != -1) {
      x = 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      x = 'Firefox';
    }
    else if ((navigator.userAgent.indexOf("Trident") != -1)) //IF IE > 10
    {
      x = 'IE';
    }
    else {
      x = 'unknown';
    }

    return x;
  }

}
