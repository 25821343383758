import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-spfx-ecb',
  templateUrl: './spfx-ecb.component.html',
  styleUrls: ['./spfx-ecb.component.css']
})
export class SPFxECBComponent implements OnInit {

  public siteUrl = environment.siteUrl;

  constructor() { }

  ngOnInit() {
  }

}
