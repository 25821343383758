import { Injectable, Inject } from '@angular/core';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { fromPromise } from '../../../node_modules/rxjs/observable/fromPromise';
import { Observable } from '../../../node_modules/rxjs/Observable';
import { PdfEditings } from '../models/PdfEditor';
import { SpServiceService } from '../services/sp-service.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Http, Headers, Response } from '@angular/http';
import { Constants } from '../app.constants';
import { ResponseContentType } from '@angular/http';
import { request } from 'http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  private pdfDoc: PDFDocumentProxy;
  private _pdfEditings: PdfEditings[] = [];
  public currentPage: number;
  public pdfTotalpage: number;
  public libraryName = localStorage.getItem('doclibraryname');

  constructor(
    private spService: SpServiceService,
    private http: HttpClient) {
    //this.spService.doclibraryName = this.libraryName;
  }

  get PdfEditings() {
    return this._pdfEditings;
  }

  set PdfEditings(value: PdfEditings[]) {
    this._pdfEditings = value;
  }

  public updateDocumentData(values: PdfEditings[]) {
    this._pdfEditings = values;
    var Data: any;
    if (this.spService.onedrive == "true") {
      Data = {
        'CreatedBy': this.spService.currentDocumentOnedrive.createdBy.user.displayName,
        'CreatedEmailAddress': this.spService.currentDocumentOnedrive.createdBy.user.email,
        'CreatedDate': new Date(this.spService.currentDocumentOnedrive.fileSystemInfo.createdDateTime).toISOString(),
        'JSONdata': JSON.stringify(JSON.stringify(this._pdfEditings)),
        'ModifiedBy': this.spService.currentDocumentOnedrive.lastModifiedBy.user.displayName,
        'ModifiedEmailAddress': this.spService.currentDocumentOnedrive.lastModifiedBy.user.email,
        'ModifiedDate': new Date().toISOString(),
        'TenantID': this.spService.tenantID,
        'LibraryName': 'Onedrive',
        'Filename': this.spService.currentDocumentOnedrive.name,
        'SiteUrl': this.spService.currentDocumentOnedrive.webUrl,
        'WebServerRelativeUrl': this.spService.currentDocumentOnedrive.webUrl,
        'DocumentGUID': this.spService.currentDocument.GUID,
        'MarkUpCount': null,
        'LicenseType': null,
        'VersionNum': '0',
      };
    }
    else {
      let doclibrary = this.spService.currentDocument.FileRef.split('/');

      Data = {
        'CreatedBy': this.spService.currentDocument.Author.Title,
        'CreatedEmailAddress': this.spService.currentDocument.Author.EMail,
        'CreatedDate': this.spService.currentDocument.Created,
        'JSONdata': JSON.stringify(JSON.stringify(this._pdfEditings)),
        'ModifiedBy': this.spService.currentDocument.Editor.Title,
        'ModifiedEmailAddress': this.spService.currentDocument.Editor.EMail,
        'ModifiedDate': new Date().toISOString(),
        'TenantID': this.spService.tenantID,
        'LibraryName': this.spService.doclibraryName,
        'Filename': doclibrary[doclibrary.length - 1].toString(),
        'SiteUrl': this.spService.currentDocument.EncodedAbsUrl,
        'WebServerRelativeUrl': this.spService.currentDocument.EncodedAbsUrl,
        'DocumentGUID': this.spService.currentDocument.GUID,
        'MarkUpCount': null,
        'LicenseType': null,
        'VersionNum': this.spService.currentDocument.OData__UIVersionString,
      };
    }

    return this.http.post(environment.apiBaseUrl + 'InsertData', Data);
  }

  public oGetPdf(pdfDownloadUrl: string): Observable<any> {

    // return this.http.get(pdfDownloadUrl, {            
    //   responseType: ResponseContentType.ArrayBuffer      
    // }).flatMap((res) => {            
    //   var uint8View = new Uint8Array(res.arrayBuffer());
    //   return fromPromise(this.getPdf(uint8View));
    // });

    return fromPromise(this.getPdf(pdfDownloadUrl));
  }

  public getPdf(pdfSrc: string | Uint8Array): Promise<PDFDocumentProxy> {
    return new Promise<PDFDocumentProxy>((resolve, reject) => {
      return this.loadPdfDoc(pdfSrc).then((loadedPdf: PDFDocumentProxy) => {
        this.pdfDoc = loadedPdf;
        resolve(loadedPdf);
      }, (err) => {
        console.error("errrr", err);
        reject(err);
      });
    });
  }

  private loadPdfDoc(pdfSrc: string | Uint8Array): Promise<PDFDocumentProxy> {
    return new Promise<PDFDocumentProxy>((resolve, reject) => {
      return pdfjsLib.getDocument(pdfSrc)
        .then((pdfDoc: PDFDocumentProxy) => {
          this.pdfTotalpage = pdfDoc.numPages;
          resolve(pdfDoc);
        })
        .catch((reason) => {
          reject(reason);
        });

      // var loadingTask = pdfjsLib.getDocument(pdfSrc)
      // loadingTask.promise.then((pdfDoc: PDFDocumentProxy) => {
      //   this.pdfTotalpage = pdfDoc.numPages;
      //   console.log(this.pdfTotalpage);
      //   resolve(pdfDoc);
      // }).catch((reason) => {
      //   reject(reason);
      // });
    });
  }

  public oGetPage(pageNumber: number): Observable<any> {
    return fromPromise(this.getPage(pageNumber));
  }

  public getPage(pageNumber: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.currentPage = pageNumber;
      if (this.pdfDoc) {
        return this.pdfDoc.getPage(pageNumber).then((pdfPage: PDFPageProxy) => {
          resolve(pdfPage);
        });
      }
    });
  }

  public oGetAllEditings(documentID: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'GetData/' + documentID + "_" + this.spService.tenantID);
  }

  public getBase64ImageFromUrl(imageUrl): Promise<any> {
    return new Promise((resolve, reject) => {
      var base64data;
      if(imageUrl.files){
        var blob = new Blob([imageUrl.files[0]]);
      }
      else{
        var blob = new Blob([imageUrl.split('&A')[0]]);
      }
      
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      return reader.onloadend = () => {
        base64data = reader.result;
        resolve(base64data);
      };
    });
  }

  public getBase64ImageFromUrl2(imageUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      var base64data;
      request.open('GET', imageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        var reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(request.response);
      };
      request.send();
    });
  }


}
