export class Constants {

  //public static context: any;
  public static annotations = 'Annotations';
  public static arrows = 'Arrows';
  public static callouts = 'Callouts';

  public static saveEditings = 'Edited data save successfuly.';
  public static readOnlyMessage = 'You have <b>Read Only</b> access for this document. To edit this document please contact your administrator.';
  public static monthlyLimitReached = 'Your monthly limit for Save/Download/Share Link pdf is reached!';
  public static savePDF = 'Edited PDF save successfully!';
  public static downloadPDF = 'Edited PDF file is added to your download queue.';
  public static remainigData = 'Remaining Save/Download/Share Link counts is : ';
  public static send = 'Send Successfully!';

  //public static froalaEditorKey = 'RA3A3B15C10iB6E5E4H5I2I3B10B6C5D5C-11NGNe1IODMGYNSFKV==';
  public static froalaEditorKey = 'vYA6mC5D5E4H3C3A6dNSWXf1h1MDb1CF1PLPFf1C1EESFKVlA3C11A8C6D2B4E4G2C3F3==';
  //public static apiBaseUrl = 'https://pdfeditorwebapp.azurewebsites.net/api/values/'; //'http://localhost:43956/api/values/'; 

  public static groupPDFEditorAdmins = 'You have not created "PDFEditorAdmins" Group in your sharepoint, this group is required to use the "Change-Admin" feature.';
  public static currentlyEditnig = 'Sorry, You can not edit this document. currently, this document being edited by ';
  public static copiedToClipboard = 'copied to clipboard';
  public static warnSeverity = 'warn';
  public static errorSeverity = 'error';
  public static successSeverity = 'success';
  public static infoSeverity = 'info';

  public static warnSummary = 'Warn Message';
  public static errorSummary = 'Error Message';
  public static successSummary = 'Success Message';
  public static infoSummary = 'Info Message';
  public static userNotExists='Please enter valid user!';

  public static OAuthSettings = {
    //appId: '35cd5626-4e64-4da5-9da5-8230c79f6e24', //local
    appId: '3df24e77-5204-406a-83b6-088c52cfec21', //Production, Dev, QA   
    scopes: [
      "user.read",
      "files.read",
      "files.read.all",
      "files.readwrite",
      "files.readwrite.all",
      "Mail.Send",
      "Sites.Read.All",
      "Sites.ReadWrite.All",
      "User.ReadBasic.All",
      "User.ReadWrite",      
    ]
  }
  public static spPDFLoaderCSS = `/* Modal Content */
    .modal {        
        position: fixed; /* Stay in place */
        display: block;
        z-index: 1100; /* Sit on top */
        padding-top: 200px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */    
        transition: 2s;
    }  
    
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;    
        width: 30em;    
        border-radius: 5px;    
        /*top: 50%;*/
        left: 50%;
        position: fixed;    
        margin-left: -15em;
        animation: 1s ease-out 0s 1 slideInFromTop;
    }
    
    @keyframes slideInFromTop {
        0% {
          transform: translateY(-50%);
        }
        50% {
          transform: translateY(0);
        }
      }
    
    /* The Close Button */
    #close {
        color: black;
        float: right;    
        font-size: 30px;
        font-weight: bold;
        margin-top: -8px;
    }
    
    #close:hover,
    #close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
    
    #close1 {
        color: black;
        float: right;    
        font-size: 30px;
        font-weight: bold;
        margin-top: -8px;
    }
    
    #close1:hover,
    #close1:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
      .okbutton{
        background-color: rgb(0, 114, 198);
        border: none;
        color: white;
        padding: 7px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        float: right;
        margin-top: 10px;
        cursor: pointer;
    }
    .cancelbutton{
        background-color: #cccccc;
        border: none;
        color: white;
        padding: 7px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        float: right;
        margin-top: 10px;
        cursor: pointer;   
    }
    .content{
        font-size: 18px;
        color: black;
    } 
    .blockui{
        z-index: 1050; 
        border: none; 
        margin: 0px; 
        padding: 0px; 
        width: 100%; 
        height: 100%; 
        top: 0px; 
        left: 0px; 
        background-color: rgb(0, 0, 0); 
        opacity: 0.6; cursor: wait;         
      }
      .modal-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;        
        border-top: 1px solid #e9ecef;
      }
    
    
    .spapp {
        background-color: white;
      }
    
    .documentDiv{
        text-align: center;
          cursor: pointer;
          /* margin-bottom: 10px; */
          /* margin-top: 10px; */
          /* border: 1px solid; */
          margin: 1px;
          float: left;
          width: 200px;
          height: 300px;
          margin: 1px; 
          display: block !important;
    }
    
    .documentDiv:hover{
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;   
    }
    
    .pdfIcon{
      font-size:65px;margin-bottom:20px;color:red
    }
    
    .ui-growl-message-success {
      background-color: #4CAF50 !important;
      border-color :#4CAF50 !important;
      background :#4CAF50 !important;
      border: 1px solid #4CAF50 !important;
    }
    
    .ui-state-highlight {
      border: none !important; 
      background: none !important;    
      color: #363636/*{fcHighlight}*/; 
    }
    
    .documentsDiv{
      width: 90%;
        float: left;
        display: block;
        overflow: hidden;
        background: #fff;
        text-align: center;
        height: 100%;
        width: 100%;
        position: relative;
        -moz-box-shadow: 1px 1px 2px #ddd;
        -webkit-box-shadow: 1px 1px 2px #ddd;
        box-shadow: 1px 1px 2px #ddd;
        padding: 15px;
    }
    
    .divCente{
      text-align: center;
    }
    
    .documenthover0{  
      color: #F78888;      
    }
    
    .documenthover1{    
      color: #F3D250;      
    }
    .documenthover2{  
      color: #86C232;      
    }
    .documenthover3{  
      color: #3AAFA9;      
    }
    .documenthover4{  
      color: #5DA2D5;      
    }
    .documenthover5{  
      color: #D83F87;      
    }
    .documenthover6{  
      color: #FFE400;      
    }
    .documenthover7{  
      color: #66FCF1;  
    }
    .documenthover8{  
      color: #FF652F;  
    }
    .documenthover9{      
      color: #14A76C;  
    }
    
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: .1s ease;  
      padding: 10px;
      text-align: left;
      background-color: black;
      /* animation: myfirst 1s; */
    }
    
    @-webkit-keyframes myfirst {
      0% {left: 0px; top: -50px;}
    }
    
    @keyframes myfirst {
      0% {left: 0px; top: -50px;}          
    }
    
    .documentsDiv:hover .overlay {
      
      opacity: 1;
      
    }
    
    .blinking{
        animation:blinkingText 0.5s infinite;
    }
    @keyframes blinkingText{
        0%{		color: #000;	}
        49%{	color: transparent;	}
        50%{	color: transparent;	}
        99%{	color:transparent;	}
        100%{	color: #fff;	}
    }
    
    .topanimation{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;  
      transition: .1s ease;    
      padding: 10px;
    }
    
    .overlay:hover .topanimation{
      animation: myfirst 1s;
    }
    
    /************************** D *******************/
    
    .field-tip {
      position:relative;  
    }
    .field-tip .tip-content {
        position:absolute;
        top:-4px; /* - top padding */
        right:9999px;
        z-index: 100;
        text-align: left;
        width:280px;
        margin-right:-195px; /* width + left/right padding */
        padding:10px;
        color:#fff;
        background:#333;
        -webkit-box-shadow:2px 2px 5px #aaa;
            -moz-box-shadow:2px 2px 5px #aaa;
                box-shadow:2px 2px 5px #aaa;
        opacity:0;
        -webkit-transition:opacity 250ms ease-out;
            -moz-transition:opacity 250ms ease-out;
            -ms-transition:opacity 250ms ease-out;
              -o-transition:opacity 250ms ease-out;
                transition:opacity 250ms ease-out;
    }
    /* <http://css-tricks.com/snippets/css/css-triangle/> */
    .field-tip .tip-content:before {
        content:' '; /* Must have content to display */
        position:absolute;
        top:50%;
        left:-16px; /* 2 x border width */
        width:0;
        height:0;
        margin-top:-8px; /* - border width */
        border:8px solid transparent;
        border-right-color:#333;
    }
    .field-tip:hover .tip-content {
        right:-20px;
        opacity:0.85;
    }
    
    .message {
      opacity: 0;
      transition: 0.5s;
    }
    
    .splButton{
        height: 30px; margin-top: 20px;margin-right: 15px;
    }
    .splButton1{
        height: 30px; margin-top: 20px;margin-right: 15px;
    }
    
    .message.active {
      opacity: 1;
    }`;

  public static textEditorCSS = `
    .divArea:hover{    
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
        cursor: move;          
    }
    .divArea:hover .signaturemove{
        display: block;
    }
    .divArea:hover .textArea{
        border: 1px solid black;
    }
    .textArea{
        cursor: text;
        min-width: 200px;
    }

    .signaturemove{
        display: none;
        width: 25px;    
        height: 25px;
        text-align: center;
        cursor: move;
        margin-top: -10px;
    }`;

  public static PDFViewerCSS = `
    canvas {
        background-color: white;
        cursor: text;
    }
    
    .white-bg {
        background-color: white;
    }
    
    .black-bg {
        background-color: black;
    }
    .yellow-bg{
        background-color: #FBFFB5;
        opacity: 0.6;
    }
    
    .white-bg:hover {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;             
    }

    .white-bg:hover .remove, .white-bg:hover .div1, .white-bg:hover .div2, .white-bg:hover .div3{
        display: block;
    }  
    
    .black-bg:hover {
        outline: 1px dotted white;
        outline: 5px auto -webkit-focus-ring-color;   
        opacity: 1;        
    }

    .black-bg:hover .remove{
        display: block;
        border: 1px solid white;
        color: white
    }
    .black-bg:hover .div1, .black-bg:hover .div2, .black-bg:hover .div3{
        display: block;
    }
    
    .yellow-bg:hover {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;               
    }
    .yellow-bg:hover .remove, .yellow-bg:hover .div1, .yellow-bg:hover .div2, .yellow-bg:hover .div3{
        display: block;
        opacity: 1;
    }
    
    .imageDiv:hover , .arrowDiv:hover {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;       
        cursor: pointer;        
    }

    .imageDiv:hover .removeImage, .arrowDiv:hover .removeImage{        
        opacity: 1;
    }
    .imageDiv:hover .roatedme, .arrowDiv:hover .roatedme{        
        opacity: 1;
    }
    .imageDiv:hover .colorPicker, .arrowDiv:hover .colorPicker{
        opacity: 1;
    }
    .imageDiv:hover .div1, .imageDiv:hover .div2, .imageDiv:hover .div3, .arrowDiv:hover .div1, .arrowDiv:hover .div2, .arrowDiv:hover .div3{
        display: block;
    }
    
    .widget-container {
        position: absolute !important;  
        top:0px;
        left:0px;
    }
    
    .imageMainDiv{
        width: 0px !important;
    }
    
    .widget {
        position: absolute;
    }
    
    .widget.moving {
        z-index: z-index + 1;
    }
    
    .widget-placeholder {
        position: absolute;
    }
    
    .dark-bg {
        background-color: #282828;
    }
    
    .remove{
        display: none;
        cursor: pointer;
        width: 25px;
        height: 25px;        
        text-align: center;
    }
    
    .removeImage{
        opacity: 0;
        cursor: pointer;
        width: 25px;
        height: 25px;        
        text-align: center;        
        margin-right: 10px;
        float: left;
        margin-top: -25px;
    }
    .removeImage:hover{
        opacity: 1;
    }
    
    .colorPicker{
        opacity: 0;
        margin-top: -20px;
        width: 25px;
        float: right;
        width: 25px;
        height: 25px;        
        text-align: center;
        cursor: pointer;
    }
    
    .colorPicker:hover{
        opacity: 1;
    }
    
    .roatedme{
        
        height: 25px;
        width: 25px;
        /* border: 1px solid black; */
        /* border-radius: 50%; */
        margin-top: -30px;
        cursor: crosshair;
        margin-left: 44%;
        opacity: 0;
        background-color: white;
        z-index: 1000;
        position: relative;
        text-align: center;
    }
    
    .roatedme:hover{
        opacity: 1;
    }
    
    .arrowImage{        
        width: 100%;
        height: 100%;
        float: left;
        z-index: 200;
        position: absolute;
    }
    
    .signature{
        height: 120px;
        width: 330px;
        float: left;        
        cursor: default;
        
    }
    .signaturemove{
        display: none;
        width: 15px;
        float: left;
        cursor: move;
    }
    
    .signatuSave{
        display: none;
        width: 15px;
        float: left; 
        cursor: pointer;   
    }
    
    .removeSignature{
        display: none;
        width: 15px;
        float: left;   
        cursor: pointer; 
    }
    
    .signatureDiv:hover .signature{
        border: 1px solid black;
    }
    .signatureDiv:hover .signaturemove{
        display: block;
    }
    .signatureDiv:hover .signatuSave{
        display: block;
    }    
    .signatureDiv:hover .removeSignature{
        display: block;
    }
                            
    .pdfCanvas{
        width: 800px !important;
        height: 1125px !important;
    }

    .pdfCanvas2{
      width: 800px !important;
      height: 1125px !important;
    }
    
    .overlay{
        width: 800px !important;
        height: 1125px !important;
    }
    
    .page{
        direction: ltr;
        width: 800px;
        height: 1125px;
        position: relative;
        overflow: visible;        
        background-clip: content-box;    
        background-color: white;
    }

    .page2{
      direction: ltr;
      width: 800px;
      height: 1125px;
      position: relative;
      overflow: visible;        
      background-clip: content-box;    
      background-color: white;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .page2Viewer{
    width: 800px; height: 1125px;float: left;overflow: hidden;margin: 0 15%;
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .page2Viewer{
    width: 800px; height: 1125px;float: left;overflow: hidden;margin: 0 15%;
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */


@media only screen and (min-width: 1280px) {
  .page2Viewer{
    width: 800px; height: 1125px;float: left;overflow: hidden;margin: 0 15%;
  }
}

@media only screen and (min-width: 1281px) {
  .page2Viewer{
    width: 800px; height: 1125px;float: left;overflow: hidden;margin: 0 25%;
  }
}
    #loader {
        position: absolute;
        left: 50%;
        top: 25%;
        z-index: 1;
        width: 90px;
        height: 90px;
        margin: -75px 0 0 -75px;
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #3498db;
        width: 90px;
        height: 90px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }
      
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    
      .blockui{
        z-index: 1050; 
        border: none; 
        margin: 0px; 
        padding: 0px; 
        width: 100%; 
        height: 100%; 
        top: 0px; 
        left: 0px; 
        background-color: rgb(0, 0, 0); 
        opacity: 0.6; cursor: wait;         
      }
    
      .pagehide{
          display: none;
      }
    
      /* The Modal (background) */
    .modal {        
        position: fixed; /* Stay in place */        
        z-index: 1100; /* Sit on top */
        padding-top: 200px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */    
        transition: 2s;
    }
    .modalCoffee {        
      position: fixed; /* Stay in place */        
      z-index: 1100; /* Sit on top */
      padding-top: 200px; /* Location of the box */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%;/*  Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(240,255,240); /* Fallback color */
      background-color: rgba(240,255,240,0.5);  /* Black w/ opacity */    
      transition: 2s;
  }
    .modal1 {        
        position: fixed; /* Stay in place */
        display: block;
        z-index: 1100; /* Sit on top */
        padding-top: 200px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */    
        transition: 2s;
    }
    
    /* Modal Content */
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;    
        width: 30em;    
        border-radius: 5px;            
        left: 50%;
        position: fixed;    
        margin-left: -15em;
        animation: 1s ease-out 0s 1 slideInFromTop;
    }

    
    
    @keyframes slideInFromTop {
        0% {
          transform: translateY(-50%);
        }
        50% {
          transform: translateY(0);
        }
      }
    
    /* The Close Button */
    #close {
        color: black;
        float: right;    
        font-size: 30px;
        font-weight: bold;
        margin-top: -8px;
    }
    
    #close:hover,
    #close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }

    #close1 {
        color: black;
        float: right;    
        font-size: 30px;
        font-weight: bold;
        margin-top: -8px;
    }
    
    #close1:hover,
    #close1:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
    
    .okbutton{
        background-color: rgb(0, 114, 198);
        border: none;
        color: white;
        padding: 7px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        float: right;
        margin-top: 10px;
        cursor: pointer;
    }

    .cancelbutton{
        background-color: #cccccc;
        border: none;
        color: white;
        padding: 7px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        float: right;
        margin-top: 10px;
        cursor: pointer;   
    }
    .content{
        font-size: 18px;
        color: black;
    }
    
      .modal-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;        
        border-top: 1px solid #e9ecef;
      }
    
    .informationdiv{
        max-width: 400px;
        display:block;
        height: 450px;
        float: left;
        margin: 15px;        
        /* border: 2px solid black; */
        border-radius: 10px;
        color: black;
        font-size: 15px;
        background-color: white;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
        -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
    }
    .informationdiv1{
      max-width: 400px;
      display:block;
      height: 450px;
      float: left;
      margin: 15px;        
      /* border: 2px solid black; */
      border-radius: 10px;
      color: black;
      font-size: 15px;
      background-color: white;
      box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
      -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
      -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
  }
    
    .collapsedClass
    {
      display:none;
    }
    .message {
        opacity: 0;
        transition: 0.5s;
      }
      
      .message.active {
        opacity: 1;
      }
    
    .wordBreak{
        word-break: break-all;
    }
    .textAlignRight{
        text-align: right;
    }
    .CalloutTextarea{
        height: 50%;
        width: 75%;
        z-index: 300;
        position: absolute;
        margin-top: 9%;
        margin-left: 9%;
        /* text-align: center; */        
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        pointer-events: none;
    }
    
    .ui-growl-message-success {
        background-color: #4CAF50 !important;
        border-color :#4CAF50 !important;
        background :#4CAF50 !important;
        border: 1px solid #4CAF50 !important;
    }
        
    .ui-state-highlight {
        border: none !important; 
        background: none !important;    
        color: #363636/*{fcHighlight}*/; 
      } 
      
    .selected{
        border: 1px solid ; 
    }
    
    .div1{
        width: 5%;
        height: 5%;
        background-color: white;
        border: 1px solid black;
        float: left;
        left: 47.5%;
        top: 95%;
        position: absolute;
        display: none;
    }
    
    .div2{
        width: 5%;
        height: 5%;
        background-color: white;
        border: 1px solid black;
        float: left;
        left: 95%;
        top: 47.5%;
        position: absolute;
        display: none;
    }
    
    .div3{
        width: 5%;
        height: 5%;
        background-color: white;
        border: 1px solid black;
        float: left;
        left: 95%;
        top: 95%;
        position: absolute;
        display: none;
    }`;

  public static PDFEditorCSS = `
    .app {
        background-color: #e1e1e1;
        background-image: url('../../assets/texture.png');                                    
    }      

      .surface {
        position: relative;
        margin: 0 auto;
        min-height: 1190px;
        //overflow: hidden;
        border: 1px solid black;
    
        > * {
          text-align: left;
        }
      }
    
      .tool {
        display: inline-block;
        position: absolute;
        z-index: 10;
      }
    
      .pdf-viewer {
        position: relative;
        z-index: 0;
        display: block;
        max-width: 98%;
        margin: 10px auto;
      }
    
      .active{
        background-color: #2399e5 !important;
      }
    
      
      h1 {
        color: purple;
      }
      
      .textTool {
        background-color: blue;
        position: absolute;
        height: 20px;
        width: 100px;
      }
    
      #loader {
        position: absolute;
        left: 50%;
        top: 25%;
        z-index: 1;
        width: 90px;
        height: 90px;
        margin: -75px 0 0 -75px;
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #3498db;
        width: 90px;
        height: 90px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }
      .modalCoffee {        
        position: fixed; /* Stay in place */        
        z-index: 1100; /* Sit on top */
        padding-top: 200px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%;/*  Full height */
        overflow: auto; /* Enable scroll if needed */       
        background-color: rgba(240,255,240,0.5); /* Black w/ opacity */    
        transition: 2s;
    }
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
              
      .stamp-delete {
        color: red;
        cursor: pointer;
        float: right;
      } 
      /* Modal Content */
      .modal {        
        position: fixed; /* Stay in place */
        display: block;
        z-index: 1100; /* Sit on top */
        padding-top: 200px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */    
        transition: 2s;
    }
    
    .modalSignature {        
      position: fixed; /* Stay in place */        
      z-index: 1100; /* Sit on top */
      padding-top: 200px; /* Location of the box */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.4); /* Black w/ opacity */    
      transition: 2s;
    }

    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;    
        width: 30em;    
        border-radius: 5px;    
        /*top: 50%;*/
        left: 50%;
        position: fixed;    
        margin-left: -15em;
        animation: 1s ease-out 0s 1 slideInFromTop;
    }

    .modal-contentSignature {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;    
      width: auto;    
      border-radius: 5px;    
      /*top: 50%;
      left: 33%;*/
      margin-left: 25%;
      margin-right: 25%;
      position: fixed;          
      animation: 1s ease-out 0s 1 slideInFromTop;
  }
    
    @keyframes slideInFromTop {
        0% {
          transform: translateY(-50%);
        }
        50% {
          transform: translateY(0);
        }
      }
    
    /* The Close Button */
    #close {
        color: black;
        float: right;    
        font-size: 30px;
        font-weight: bold;
        margin-top: -8px;
    }
    
    #close:hover,
    #close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }

    #close1 {
        color: black;
        float: right;    
        font-size: 30px;
        font-weight: bold;
        margin-top: -8px;
    }
    
    #close1:hover,
    #close1:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
      .okbutton{
        background-color: rgb(0, 114, 198);
        border: none;
        color: white;
        padding: 7px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        float: right;
        margin-top: 10px;
        cursor: pointer;
    }
    .cancelbutton{
        background-color: #cccccc;
        border: none;
        color: white;
        padding: 7px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        float: right;
        margin-top: 10px;
        cursor: pointer;   
    }
    .content{
        font-size: 18px;
        color: black;
    } 
    .blockui{
        z-index: 1050; 
        border: none; 
        margin: 0px; 
        padding: 0px; 
        width: 100%; 
        height: 100%; 
        top: 0px; 
        left: 0px; 
        background-color: rgb(0, 0, 0); 
        opacity: 0.6; cursor: wait;         
      }
      .modal-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;        
        border-top: 1px solid #e9ecef;
      }`;
  
}